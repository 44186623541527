import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Button,
  TextField,
  styled,
} from "@mui/material";
import {
  createComment,
  deleteWorkOrder,
  getWorkOrderById,
  getWorkOrderDocument,
} from "../api/workOrders";
import dayjs from "dayjs";
import translationsEn from "../i18n/translations_en";
import themeOptions from "../style/componentsStyle";
import { useAuth } from "../provider/authProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteModal from "../modals/confirmationsModal/deleteModal";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Label } from "@mui/icons-material";
import { useAlert } from "../utils/alertContext";

export default function ViewWorkOrder() {
  const { id } = useParams<{ id: any }>();
  const [workOrder, setWorkOrder] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { loggedUserId, loggedUserRole } = useAuth();
  const [modalStates, setModalStates] = useState({ delete: false });
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { showAlert } = useAlert();

  const handleOpenModal = (modalType: string, id: number) => {
    setDeleteId(id);
    setModalStates((prevState) => ({
      ...prevState,
      [modalType]: true,
    }));
  };

  const handleCloseModal = (modalType: string) => {
    setDeleteId(null);
    setModalStates((prevState) => ({
      ...prevState,
      [modalType]: false,
    }));
  };

  const handleDeleteOvertime = (workOrderId: number) => {
    handleOpenModal("delete", workOrderId);
  };

  const handleDelete = async () => {
    if (deleteId === null) return;

    try {
      await deleteWorkOrder(deleteId);
      navigate("/workOrders");
    } catch (error) {
      console.error("Error deleting work order:", error);
    } finally {
      handleCloseModal("delete");
    }
  };
  const getStatusLabel = (status: string) => {
    return (
      translationsEn.status[status as keyof typeof translationsEn.status] ||
      status
    );
  };

  const handleDownload = async (
    workOrderId: any,
    documentId: any,
    defaultFilename: string
  ) => {
    try {
      const { blob, headers } = await getWorkOrderDocument(
        workOrderId,
        documentId
      );

      const contentDisposition = headers["content-disposition"];
      let filename = defaultFilename;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch?.[1]) {
          filename = filenameMatch[1];

          const parts = filename.split("__");
          if (parts.length > 1) {
            filename = parts.slice(-1)[0];
          }

          filename = filename.replace(/^.*__/, "").trim();
        }
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.remove();

      showAlert("Successfully downloaded", "success");
    } catch (error) {
      console.error("Error downloading file:", error);
      showAlert("Failed to download the file", "error");
    }
  };

  useEffect(() => {
    const fetchWorkOrder = async () => {
      try {
        const data = await getWorkOrderById(id);
        setWorkOrder(data);
      } catch (error) {
        console.error("Error fetching work order:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchWorkOrder();
  }, [id]);

  const PageContainer = styled("div")({
    maxWidth: "1500px",
    margin: "auto",
    height: "100%",

    display: "flex",
    flexDirection: "column",
  });

  const File = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100px",
    fontSize: "15px",
    backgroundColor: "#e6e6e6",
    borderRadius: "5px",
    padding: "4px",
    margin: "4px",
    cursor: "pointer",
  });

  const formik = useFormik({
    initialValues: {
      commentText: "",
    },
    validationSchema: Yup.object().shape({
      commentText: Yup.string().required("Comment is required"),
    }),
    onSubmit: async (values) => {
      const payload = {
        text: values.commentText,
        employeeId: loggedUserId,
        commentableId: workOrder.data.commentable.id,
      };
      try {
        await createComment(payload);
        formik.resetForm();

        const updatedData = await getWorkOrderById(id);
        setWorkOrder(updatedData);
      } catch (error) {
        console.error("Error posting comment:", error);
      }
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (!workOrder) {
    return <Typography>No work order found.</Typography>;
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography
            variant="h4"
            style={{ margin: "40px", fontWeight: "bold", color: "#555555" }}
          >
            {translationsEn.workOrders.viewWorkOrder}
          </Typography>
        </Grid>
        <Grid item>
          {loggedUserRole !== 2 && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
                borderRadius: "10px",
                marginRight: "20px",
              }}
              onClick={() => handleDeleteOvertime(workOrder.data.id)}
            >
              Delete work order
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Work Order Details */}
      <Paper style={{ width: "99%" }}>
        <Grid container spacing={3} style={{ padding: "30px" }}>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            style={{ marginBottom: "20px" }}
          >
            {[
              {
                label: translationsEn.workOrders.title,
                value: workOrder.data.title,
              },
              {
                label: translationsEn.workOrders.id,
                value: workOrder.data.id,
              },
              {
                label: translationsEn.workOrders.created,
                value: dayjs(workOrder.data.created).format("DD-MM-YYYY"),
              },
              {
                label: translationsEn.workOrders.status,
                value: getStatusLabel(workOrder.data.status),
              },
              {
                label: translationsEn.workOrders.reporter,
                value: `${workOrder.data.reporter?.firstName} ${workOrder.data.reporter?.lastName}`,
              },
            ].map(({ label, value }) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={label}>
                <Typography variant="body1">
                  <strong>{label}:</strong> {value}
                </Typography>
              </Grid>
            ))}
          </Grid>

          {/* Second Row */}
          <Grid
            container
            item
            xs={12}
            spacing={3}
            style={{ marginBottom: "20px" }}
          >
            {[
              {
                label: translationsEn.workOrders.companyType,
                value: workOrder.data.companyType,
              },
              {
                label: translationsEn.workOrders.companyName,
                value: workOrder.data.company.name,
              },
              {
                label: translationsEn.workOrders.assignedUser,
                value: `${workOrder.data.assigned?.firstName} ${workOrder.data.assigned?.lastName}`,
              },
              {
                label: translationsEn.workOrders.deadline,
                value: `${dayjs(workOrder.data.deadlineDate).format(
                  "DD-MM-YYYY"
                )} ${workOrder.data.deadlineTime}`,
              },
              {
                label: translationsEn.workOrders.location,
                value: workOrder.data.location,
              },
            ].map(({ label, value }) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={label}>
                <Typography variant="body1">
                  <strong>{label}:</strong> {value}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: "10px" }}>
              <strong>{translationsEn.workOrders.description}:</strong>{" "}
              {workOrder.data.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: "10px" }}>
              <strong>{translationsEn.workOrders.document}:</strong>
            </Typography>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "auto",
                }}
              >
                {workOrder.data.documents?.map((item: any) => {
                  const formattedFilename = item.filename
                    .split("__")
                    .slice(-1)[0];
                  return (
                    <File
                      key={item.id}
                      onClick={() =>
                        handleDownload(
                          workOrder.data.id,
                          item.id,
                          `${formattedFilename}`
                        )
                      }
                    >
                      <InsertDriveFileIcon fontSize="large" />
                      {formattedFilename}
                    </File>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        style={{
          margin: "10px",
          maxHeight: "150px",
          overflowX: "auto",
        }}
      >
        <Typography
          variant="h6"
          style={{ margin: "10px", fontWeight: "bold", color: "#555555" }}
        >
          Comments
        </Typography>
        {workOrder.data.commentable.comments.length > 0 ? (
          workOrder.data.commentable.comments.map((comment: any) => (
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                padding: "10px",
              }}
              key={comment.id}
            >
              <Grid item xs={12}>
                <Typography variant="body1" style={{ fontWeight: "bolder" }}>
                  {comment.employee.firstName} {comment.employee.lastName} :
                </Typography>
                <Typography variant="body1">{comment.text}</Typography>
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography style={{ margin: "10px" }}>No comments yet</Typography>
        )}
      </Paper>
      <Paper
        style={{
          margin: "10px",
          padding: "10px",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Add comment"
                id="commentText"
                name="commentText"
                value={formik.values.commentText}
                onChange={(e) =>
                  formik.setFieldValue("commentText", e.target.value)
                }
                onBlur={formik.handleBlur}
                fullWidth
                style={{ borderRadius: "15px" }}
                error={
                  formik.touched.commentText &&
                  Boolean(formik.errors.commentText)
                }
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
                style={{ background: themeOptions.palette.primary.main }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <DeleteModal
        open={modalStates.delete}
        handleClose={() => handleCloseModal("delete")}
        onConfirm={handleDelete}
      />
    </>
  );
}
