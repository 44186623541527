import { Box, Button, Grid, Paper, styled } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getVehicleById,
  getDocumentByVehicleId,
  getVehicleAddOns,
  downloadDocument,
  getChangelogForVehicle,
} from "../../api/vehiclesApi";
import translationsEn from "../../i18n/translations_en";
import { useAlert } from "../../utils/alertContext";
import { useLocation } from "react-router-dom";
import themeOptions from "../../style/componentsStyle";
import VehiclePreviewServiceModal from "./vehiclesPreviewModal";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useAuth } from "../../provider/authProvider";
import ChangeLogModal from "./changeLogModal";

const Typography = styled("div")({
  fontWeight: "bold",
});
const File = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100px",
  fontSize: "15px",
  backgroundColor: "#e6e6e6",
  borderRadius: "5px",
  padding: "4px",
  margin: "4px",
  cursor: "pointer",
});
const Content = styled("div")(({ marginTop }: { marginTop: string }) => ({
  width: "1000px",
  margin: "auto",
  padding: "30px 10px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  marginTop: marginTop,
}));

const useResponsiveHeight = () => {
  const [height, setHeight] = useState("853px");

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1536) {
        // When HD screen with 125% zoom (or similar size)
        setHeight("650px");
      } else {
        // Normal screen size
        setHeight("853px");
      }
    };

    // Check on initial render and when window resizes
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return height;
};

export default function VehiclePreview() {
  const { showAlert } = useAlert();
  const [vehicleData, setVehicleData] = useState<any>({});
  const [vehicleService, setVehicleService] = useState<any[]>([]);
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [changeLogModal, setChangeLogModal] = useState<boolean>(false);
  const [newestService, setNewestService] = useState<any>(null);
  const { loggedUserRole } = useAuth();
  const location = useLocation();
  const { previewItem } = location.state;
  const responsiveHeight = useResponsiveHeight();

  const style = {
    position: "absolute" as "absolute",
    left: "20%",
    bgcolor: "background.paper",
    height: responsiveHeight,
    width: "1300px",
    borderRadius: "10px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "2px solid #ccc",
    overflow: "auto",
  };

  useEffect(() => {
    if (previewItem.id) {
      getVehicleById(previewItem.id)
        .then((response) => {
          setVehicleData(response.data);
          return getDocumentByVehicleId(previewItem.id);
        })
        .then((documentResponse) => {
          setVehicleData((prevVehicleData: any) => ({
            ...prevVehicleData,
            documents: documentResponse,
          }));
          return getVehicleAddOns(previewItem.id);
        })
        .then((addOnsResponse) => {
          const services = addOnsResponse.data;

          const sortedServices = services.sort(
            (a: any, b: any) =>
              new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime()
          );

          setVehicleService(sortedServices);
          setNewestService(sortedServices[0]);
        })
        .catch((error) => showAlert(error, "error"));
    }
  }, [previewItem]);

  const handleDownload = async (
    vehicleId: any,
    documentId: any,
    defaultFilename: string
  ) => {
    try {
      const { blob, headers } = await downloadDocument(vehicleId, documentId);

      const contentDisposition = headers["content-disposition"];
      let filename = defaultFilename;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch?.[1]) {
          filename = filenameMatch[1];

          const parts = filename.split("__");
          if (parts.length > 1) {
            filename = parts.slice(-1)[0];
          }

          filename = filename.replace(/^.*__/, "").trim();
        }
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.remove();

      showAlert("Successfully downloaded", "success");
    } catch (error) {
      console.error("Error downloading file:", error);
      showAlert("Failed to download the file", "error");
    }
  };
  const marginTop = newestService ? "250px" : "100px";
  return (
    <>
      <Paper>
        <Box sx={style}>
          <Content marginTop={marginTop}>
            <Grid container spacing={1}>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.vehicleName}</Typography>
                <p>{vehicleData?.vehicleName || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>
                  {translationsEn.vehicles.licencePlateNo}
                </Typography>
                <p>{vehicleData?.licencePlateNo || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>
                  {translationsEn.vehicles.firstRegistrationYear}
                </Typography>
                <p>{vehicleData?.firstRegistrationYear || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.vehicleUser}</Typography>
                <p>
                  {vehicleData?.vehicleUser?.firstName +
                    " " +
                    vehicleData?.vehicleUser?.lastName || "No Data"}
                </p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.brand}</Typography>
                <p>{vehicleData?.brand || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.homologation}</Typography>
                <p>{vehicleData?.homologationType || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.commercial}</Typography>
                <p>{vehicleData?.commercialName || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.vinNo}</Typography>
                <p>{vehicleData?.vinNo || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.tehnical}</Typography>
                <p>{vehicleData?.technicalMaxAllowedMass || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>
                  {translationsEn.vehicles.maximumAuthorizedMass}
                </Typography>
                <p>{vehicleData?.maximumAuthorizedMass || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.curb}</Typography>
                <p>{vehicleData?.curbWeight || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>
                  {translationsEn.vehicles.lastYearRegistration}
                </Typography>
                <p>{vehicleData?.lastRegistrationYear || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.category}</Typography>
                <p>{vehicleData?.category || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.noAxles}</Typography>
                <p>{vehicleData?.numberOfAxles || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.displacement}</Typography>
                <p>{vehicleData?.displacement || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>
                  {translationsEn.vehicles.engineHorsepower}
                </Typography>
                <p>{vehicleData?.engineHorsepower || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.fuelType}</Typography>
                <p>{vehicleData?.fuelType || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.numberOfSeats}</Typography>
                <p>{vehicleData?.numberOfSeats || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>
                  {translationsEn.vehicles.standingCapacity}
                </Typography>
                <p>{vehicleData?.standingCapacity || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.maximumSpeed}</Typography>
                <p>{vehicleData?.maxSpeed || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>
                  {translationsEn.vehicles.stationaryNoise}
                </Typography>
                <p>{vehicleData?.stationaryNoise || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.co2}</Typography>
                <p>{vehicleData?.co2Emission || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.ecoCategory}</Typography>
                <p>{vehicleData?.ecoCategory || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.ownerOIB}</Typography>
                <p>{vehicleData?.ownerOIB || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.bodyType}</Typography>
                <p>{vehicleData?.bodyType || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.purpose}</Typography>
                <p>{vehicleData?.purpose || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>
                  {translationsEn.vehicles.firstRegistrationInCountry}
                </Typography>
                <p>{vehicleData?.firstRegistrationInCountry || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.tireSize}</Typography>
                <p>{vehicleData?.tireSize || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.make}</Typography>
                <p>{vehicleData?.model || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.KmOnInput}</Typography>
                <p>{vehicleData?.kmOnInputDay || "No Data"}</p>
              </Grid>
              <Grid item xs={3} direction={"column"}>
                <Typography>{translationsEn.vehicles.kmOnDay}</Typography>
                <p>{vehicleData?.kmOnDay || "No Data"}</p>
              </Grid>
              {loggedUserRole?.id !== 1 && (
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    style={{
                      margin: "10px",
                      borderRadius: "20px",
                      height: "50%",
                    }}
                    sx={{
                      backgroundColor: themeOptions.palette.primary.main,
                      color: "white",
                      ":hover": {
                        background: themeOptions.palette.primary.hover,
                      },
                      textTransform: "none",
                    }}
                    onClick={() => setChangeLogModal(true)}
                  >
                    {translationsEn.vehicles.changeLog}
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflow: "auto",
                  }}
                >
                  {vehicleData.documents?.data?.map((item: any) => {
                    // Assuming item.fileName contains the correct filename
                    const formattedFilename = item.fileName
                      .split("__")
                      .slice(-1)[0]; // Extract the relevant part of the filename

                    return (
                      <File
                        key={item.id}
                        onClick={() =>
                          handleDownload(
                            vehicleData.id,
                            item.id,
                            `${formattedFilename}`
                          )
                        }
                      >
                        <InsertDriveFileIcon fontSize="large" />
                        {formattedFilename}
                      </File>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      fontSize: "25px",
                    }}
                  >
                    {translationsEn.vehicles.service}
                  </Typography>
                  <Button
                    variant="contained"
                    style={{
                      margin: "10px",
                      borderRadius: "20px",
                      height: "50%",
                    }}
                    sx={{
                      backgroundColor: themeOptions.palette.primary.main,
                      color: "white",
                      ":hover": {
                        background: themeOptions.palette.primary.hover,
                      },
                      textTransform: "none",
                    }}
                    onClick={() => setPreviewModal(true)}
                  >
                    {translationsEn.addServiceVehicle.viewHistory}
                  </Button>
                </Grid>

                {newestService ? (
                  <Grid
                    container
                    spacing={2}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography>
                        {translationsEn.vehicles.typeOfService}
                      </Typography>
                      <p>{newestService.typeOfService}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {translationsEn.vehicles.description}
                      </Typography>
                      <p>{newestService.description}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {translationsEn.addServiceVehicle.contactPerson}
                      </Typography>
                      <p>{newestService.contactPerson}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {translationsEn.addServiceVehicle.lastPrice}
                      </Typography>
                      <p>{newestService.lastPrice}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {translationsEn.addServiceVehicle.validFrom}
                      </Typography>
                      <p>{newestService.validFrom}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {translationsEn.addServiceVehicle.validTo}
                      </Typography>
                      <p>{newestService.validTo}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {translationsEn.vehicles.contactDetails}
                      </Typography>
                      <p>{newestService.contactDetails}</p>
                    </Grid>
                  </Grid>
                ) : (
                  <p>{translationsEn.vehicles.noData}</p>
                )}
              </Grid>
            </Grid>
          </Content>
        </Box>
      </Paper>
      <VehiclePreviewServiceModal
        open={previewModal}
        closeModal={() => setPreviewModal(false)}
        data={vehicleService} // Pass all service data
      />
      <ChangeLogModal
        open={changeLogModal}
        closeModal={() => setChangeLogModal(false)}
        id={vehicleData.id} // Pass all service data
      />
    </>
  );
}
