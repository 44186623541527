import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  createTravelRequestStep3,
  getTravelRequestStep3,
} from "../../api/travelRequestApi";
import { useNavigate } from "react-router-dom";
import AddCostModal from "./addCostModal";
import { useAlert } from "../../utils/alertContext";

const PageContainer = styled("div")({
  maxWidth: "900px",
  margin: "auto",
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
});

const conditionalValidation = (valueToMatch: string, errorMessage: string) => {
  return Yup.string().when("typeOfTransportation", {
    is: (value: any) => value !== valueToMatch,
    then: (schema) => schema.required(errorMessage),
    otherwise: (schema) => schema,
  });
};

const validationSchema = Yup.object({
  // typeOfTransportation: Yup.string().required(
  //   "Type of transportation is required"
  // ),
  // brandAndType: conditionalValidation("other", "Brand and type is required"),
  // registrationNumber: conditionalValidation(
  //   "other",
  //   "Registration number is required"
  // ),
  // pricePerKm: conditionalValidation("other", "Pricekm/eur is required"),
  // startDate: conditionalValidation("other", "Start date is required"),
  // endDate: conditionalValidation("other", "End date is required"),
  // ecoScore: conditionalValidation("other", "Eco score is required"),
  // km: conditionalValidation("other", "Km is required"),
  // totalOfEuros: conditionalValidation("other", "Total of euros is required"),
  // additionalTransportationExpenses: conditionalValidation(
  //   "other",
  //   "Additiona transportation expenses is required"
  // ),
  // totalAdditionalTransportationExpenses: conditionalValidation(
  //   "other",
  //   "Total eur additional transportation expenses is required"
  // ),
  // descriptionOfAdditionalCosts: conditionalValidation(
  //   "other",
  //   "Description of additional costs is required"
  // ),
  // totalTransportationExpenses: conditionalValidation(
  //   "other",
  //   "Total transportation expenses is required"
  // ),
});

const initialValues = {
  typeOfTransportation: "",
  brandAndType: "",
  registrationNumber: "",
  pricePerKm: "",
  startDate: "",
  endDate: "",
  ecoScore: "",
  km: "",
  totalOfEuros: "",
  additionalTransportationExpenses: "",
  totalAdditionalTransportationExpenses: "",
  descriptionOfAdditionalCosts: "",
  totalTransportationExpenses: "",
  personalVehicle: false,
};

const typesOfTransportation = [
  { value: "Company vehicle" },
  { value: "Personal vehicle" },
  { value: "Rent-a-car" },
  { value: "Other" },
];

export default function TravelExpenses(props?: any) {
  const { travelRequestID, handleNext } = props;
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const [addCostModal, setAddCostModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [travelExpenses, setTravelExpenses] = useState<any>();

  const closeAddCostModal = () => {
    setAddCostModal(false);
  };

  useEffect(() => {
    if (travelRequestID)
      getTravelRequestStep3(travelRequestID)
        .then((response) => {
          setTravelExpenses(response.data.data);
        })
        .catch((error) => showAlert(error, "error"));
  }, [travelRequestID]);

  useEffect(() => {
    if (travelExpenses) {
      formik.setFieldValue(
        "typeOfTransportation",
        travelExpenses.typeOfTransportation
      );
      formik.setFieldValue("brandAndType", travelExpenses.brandAndType);
      formik.setFieldValue(
        "registrationNumber",
        travelExpenses.registrationNumber
      );
      formik.setFieldValue("pricePerKm", travelExpenses.pricePerKm);
      formik.setFieldValue("startDate", travelExpenses.startDate);
      formik.setFieldValue("endDate", travelExpenses.endDate);
      formik.setFieldValue("ecoScore", travelExpenses.ecoScore);
      formik.setFieldValue("km", travelExpenses.km);
      formik.setFieldValue("totalOfEuros", travelExpenses.totalOfEuros);
      formik.setFieldValue(
        "additionalTransportationExpenses",
        travelExpenses.additionalTransportationExpenses
      );
      formik.setFieldValue(
        "totalAdditionalTransportationExpenses",
        travelExpenses.totalAdditionalTransportationExpenses
      );
      formik.setFieldValue(
        "descriptionOfAdditionalCosts",
        travelExpenses.descriptionOfAdditionalCosts
      );
      formik.setFieldValue(
        "totalTransportationExpenses",
        travelExpenses.totalTransportationExpenses
      );
    }
  }, [travelExpenses]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true);

      const endpointValues =
        formik.values.typeOfTransportation === "Other"
          ? { typeOfTransportation: "Other" }
          : values;

      createTravelRequestStep3(endpointValues, travelExpenses.id)
        .then(() => {
          handleNext();
          showAlert("Travel expenses added successfully!", "success");
        })
        .catch((error) => {
          showAlert(error, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <PageContainer>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              select
              margin="dense"
              label={translationsEn.createTravelRequests.typeOfTransportation}
              name="typeOfTransportation"
              value={formik.values.typeOfTransportation}
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.typeOfTransportation &&
                  formik.errors.typeOfTransportation
              )}
              helperText={
                formik.touched.typeOfTransportation &&
                formik.errors.typeOfTransportation &&
                (formik.errors.typeOfTransportation as string)
              }
              fullWidth
            >
              {typesOfTransportation.map((item) => {
                return <MenuItem value={item.value}>{item.value}</MenuItem>;
              })}
            </TextField>
          </Grid>
          {formik.values.typeOfTransportation !== "Other" ? (
            <>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label={translationsEn.createTravelRequests.brandAndType}
                  name="brandAndType"
                  value={formik.values.brandAndType}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.brandAndType && formik.errors.brandAndType
                  )}
                  helperText={
                    formik.touched.brandAndType &&
                    formik.errors.brandAndType &&
                    (formik.errors.brandAndType as string)
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label={translationsEn.createTravelRequests.registrationNumber}
                  name="registrationNumber"
                  value={formik.values.registrationNumber}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.registrationNumber &&
                      formik.errors.registrationNumber
                  )}
                  helperText={
                    formik.touched.registrationNumber &&
                    formik.errors.registrationNumber &&
                    (formik.errors.registrationNumber as string)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  type="number"
                  label={translationsEn.createTravelRequests.pricePerKm}
                  name="pricePerKm"
                  value={formik.values.pricePerKm || ""}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.pricePerKm && formik.errors.pricePerKm
                  )}
                  helperText={
                    formik.touched.pricePerKm &&
                    formik.errors.pricePerKm &&
                    (formik.errors.pricePerKm as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  label={translationsEn.createTravelRequests.startDate}
                  format="DD/MM/YYYY"
                  value={
                    formik.values.startDate
                      ? dayjs(formik.values.startDate)
                      : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      "startDate",
                      date ? date.format("YYYY-MM-DD") : ""
                    )
                  }
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.startDate &&
                        formik.errors.startDate &&
                        (formik.errors.startDate as string),
                      error: Boolean(
                        formik.touched.startDate && formik.errors.startDate
                      ),
                    },
                  }}
                  sx={{ margin: "10px 0", width: "100%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label={translationsEn.createTravelRequests.endDate}
                  format="DD/MM/YYYY"
                  value={
                    formik.values.endDate ? dayjs(formik.values.endDate) : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      "endDate",
                      date ? date.format("YYYY-MM-DD") : ""
                    )
                  }
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.endDate &&
                        formik.errors.endDate &&
                        (formik.errors.endDate as string),
                      error: Boolean(
                        formik.touched.endDate && formik.errors.endDate
                      ),
                    },
                  }}
                  sx={{ margin: "10px 0", width: "100%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  margin="dense"
                  label={translationsEn.createTravelRequests.ecoScore}
                  name="ecoScore"
                  value={formik.values.ecoScore}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.ecoScore && formik.errors.ecoScore
                  )}
                  helperText={
                    formik.touched.ecoScore &&
                    formik.errors.ecoScore &&
                    (formik.errors.ecoScore as string)
                  }
                  fullWidth
                >
                  {[1, 2, 3, 4, 5].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  label={translationsEn.createTravelRequests.km}
                  name="km"
                  value={formik.values.km || ""}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.km && formik.errors.km)}
                  helperText={
                    formik.touched.km &&
                    formik.errors.km &&
                    (formik.errors.km as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  label={translationsEn.createTravelRequests.totalOfEuros}
                  name="totalOfEuros"
                  value={formik.values.totalOfEuros || ""}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.totalOfEuros && formik.errors.totalOfEuros
                  )}
                  helperText={
                    formik.touched.totalOfEuros &&
                    formik.errors.totalOfEuros &&
                    (formik.errors.totalOfEuros as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  label={
                    translationsEn.createTravelRequests
                      .additionalTransportationExpenses
                  }
                  name="additionalTransportationExpenses"
                  value={formik.values.additionalTransportationExpenses || ""}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.additionalTransportationExpenses &&
                      formik.errors.additionalTransportationExpenses
                  )}
                  helperText={
                    formik.touched.additionalTransportationExpenses &&
                    formik.errors.additionalTransportationExpenses &&
                    (formik.errors.additionalTransportationExpenses as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  label={
                    translationsEn.createTravelRequests
                      .totalAdditionalTransportationExpenses
                  }
                  name="totalAdditionalTransportationExpenses"
                  value={
                    formik.values.totalAdditionalTransportationExpenses || ""
                  }
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.totalAdditionalTransportationExpenses &&
                      formik.errors.totalAdditionalTransportationExpenses
                  )}
                  helperText={
                    formik.touched.totalAdditionalTransportationExpenses &&
                    formik.errors.totalAdditionalTransportationExpenses &&
                    (formik.errors
                      .totalAdditionalTransportationExpenses as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label={
                    translationsEn.createTravelRequests
                      .descriptionOfAdditionalCosts
                  }
                  name="descriptionOfAdditionalCosts"
                  value={formik.values.descriptionOfAdditionalCosts}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.descriptionOfAdditionalCosts &&
                      formik.errors.descriptionOfAdditionalCosts
                  )}
                  helperText={
                    formik.touched.descriptionOfAdditionalCosts &&
                    formik.errors.descriptionOfAdditionalCosts &&
                    (formik.errors.descriptionOfAdditionalCosts as string)
                  }
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  label={
                    translationsEn.createTravelRequests
                      .totalTransportationExpenses
                  }
                  name="totalTransportationExpenses"
                  value={formik.values.totalTransportationExpenses || ""}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.totalTransportationExpenses &&
                      formik.errors.totalTransportationExpenses
                  )}
                  helperText={
                    formik.touched.totalTransportationExpenses &&
                    formik.errors.totalTransportationExpenses &&
                    (formik.errors.totalTransportationExpenses as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    color: themeOptions.palette.primary.main,
                    borderColor: themeOptions.palette.primary.main,
                    textTransform: "none",
                    marginTop: "8px",
                    height: "56px",
                  }}
                  onClick={() => setAddCostModal(true)}
                >
                  {translationsEn.createTravelRequests.addCost}
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={6}>
              <Button
                variant="outlined"
                size="large"
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  color: themeOptions.palette.primary.main,
                  borderColor: themeOptions.palette.primary.main,
                  textTransform: "none",
                  marginTop: "8px",
                  height: "56px",
                }}
                onClick={() => setAddCostModal(true)}
              >
                {translationsEn.createTravelRequests.addCost}
              </Button>
            </Grid>
          )}

          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="large"
              style={{
                width: "100%",
                backgroundColor: "white",
                color: themeOptions.palette.primary.main,
                borderColor: themeOptions.palette.primary.main,
                textTransform: "none",
                marginTop: "20px",
              }}
              disabled={loading}
              onClick={() => navigate("/travel-requests")}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                translationsEn.createTravelRequests.cancel
              )}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{
                width: "100%",
                backgroundColor: themeOptions.palette.primary.main,
                textTransform: "none",
                marginTop: "20px",
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                translationsEn.createTravelRequests.submit
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      <AddCostModal
        open={addCostModal}
        closeModal={closeAddCostModal}
        travelExpensesId={travelExpenses?.id}
      />
    </PageContainer>
  );
}
