import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import ProtectedRoute from "./ProtectedRoute";
import { AdminRoute } from "./AdminRoute";
import { useState } from "react";
import DefaultLayout from "../components/layout/DefaultLayout";
import CvOverview from "../pages/Cv";
import TravelRequests from "../pages/travel-requests/travelRequests";
import CreateTravelRequest from "../pages/travel-requests/createTravelRequest";
import Employees from "../pages/Employees";
import Teams from "../pages/Teams";
import TeamHistoryRequest from "../pages/teamHistoryRequest";
import TeamInformation from "../pages/teamsInformation";
import LoginComponent from "../pages/Login";
import Overview from "../pages/Overview";

import MyRequestOverview from "../pages/myRequest";
import AbsenceRequestOverview from "../pages/absenceRequest";
import UserInventoryOverview from "../pages/userInventory";
import ActiveDutiesOverview from "../pages/activeDuties";
import BudgetOverview from "../pages/budgetOverview";
import OvertimeOverview from "../pages/Overtime";
import EditUser from "../pages/editUser";
import CallDutyScheduler from "../pages/CallDutyScheduler";
import ShiftRecords from "../pages/shiftRecords";
// import Equipment from "../pages/Equipment";
import Companies from "../pages/Companies";
import SetPassword from "../modals/login/setPassword";
import DashboardOverview from "../pages/dashboardOverview";
import WorkOrders from "../pages/workOrders";
import NewWorkOrders from "../pages/newWorkOrders";
import React from "react";
import EditWorkOrder from "../pages/editWorkOrder";
import ViewWorkOrder from "../pages/viewWorkOrder";
import MyTravelRequests from "../pages/travel-requests/myTravelRequests";
import AllTravelRequests from "../pages/travel-requests/allTravelRequests";
import Equipment from "../pages/equipment/equipment";
import MyEquipment from "../pages/equipment/myEquipment";
import Company from "../pages/companies/company";
import VehiclesOverview from "../pages/vehicles/overviewVehicle";
import NewVehicles from "../pages/vehicles/createVehicle";
import VehiclePreview from "../pages/vehicles/vehiclePreviewPage";

const Routes = () => {
  const { token } = useAuth();
  const [userRole, setUserRole] = useState<string | undefined>("admin");

  // Define public routes accessible to all users
  const adminOnlyRoute = [
    {
      path: "/service",
      element: <AdminRoute role={userRole} children={undefined} />,
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Use Layout as the top-level component
      children: [
        {
          path: "/",
          element: <DefaultLayout />, // Wrap the component in ProtectedRoute
          children: [
            {
              path: "",
              element: <DashboardOverview />,
            },
            {
              path: "/overview",
              element: <Overview />,
            },
            {
              path: "/employees",
              element: <Employees />,
            },
            {
              path: "/cv",
              element: <CvOverview />,
            },
            {
              path: "/travel-requests",
              element: <TravelRequests />,
            },
            {
              path: "/create-travel-requests/:travelRequestID?",
              element: <CreateTravelRequest />,
            },
            {
              path: "/my-travel-requests",
              element: <MyTravelRequests />,
            },
            {
              path: "/all-travel-requests",
              element: <AllTravelRequests />,
            },
            {
              path: "/equipment",
              element: <Equipment />,
            },
            {
              path: "/teamHistoryRequest",
              element: <TeamHistoryRequest />,
            },
            {
              path: "/teams",
              element: <Teams />,
            },
            {
              path: "/teamsInformation/:id",
              element: <TeamInformation />,
            },
            {
              path: "/myRequest",
              element: <MyRequestOverview />,
            },
            {
              path: "/absenceRequest",
              element: <AbsenceRequestOverview />,
            },
            {
              path: "/userInventory",
              element: <UserInventoryOverview />,
            },
            {
              path: "/activeDuties",
              element: <ActiveDutiesOverview />,
            },
            {
              path: "/call-duty",
              element: <CallDutyScheduler />,
            },
            {
              path: "/budget",
              element: <BudgetOverview />,
            },
            {
              path: "/overtimes",
              element: <OvertimeOverview />,
            },
            {
              path: "/editUser/:id",
              element: <EditUser />,
            },
            {
              path: "/shiftRecords",
              element: <ShiftRecords />,
            },
            {
              path: "/equipment",
              element: <Equipment />,
            },
            {
              path: "/my-equipment",
              element: <MyEquipment />,
            },
            {
              path: "/companies",
              element: <Companies />,
            },
            {
              path: "/workOrders",
              element: <WorkOrders />,
            },
            {
              path: "/newWorkOrders",
              element: <NewWorkOrders />,
            },
            {
              path: "/editWorkOrder/:id",
              element: <EditWorkOrder />,
            },
            {
              path: "/viewWorkOrder/:id",
              element: <ViewWorkOrder />,
            },
            {
              path: "/company",
              element: <Company />,
            },
            {
              path: "/vehicles",
              element: <VehiclesOverview />,
            },
            {
              path: "/newVehicle",
              element: <NewVehicles />,
            },
            {
              path: "/previewVehicle",
              element: <VehiclePreview />,
            },
          ],
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/login",
      element: <LoginComponent />,
    },
    {
      path: "/set-password/:token",
      element: <SetPassword />, // Add the SetPassword route here
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...(token && userRole === "admin" ? adminOnlyRoute : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
