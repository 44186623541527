import {
  Modal,
  Box,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { useAlert } from "../../utils/alertContext";
import translationsEn from "../../i18n/translations_en";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

const Content = styled("div")({
  width: "900px",
  margin: "auto",
  padding: "30px 10px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  marginTop: "30px",
});

export default function VehiclePreviewServiceModal(props: any) {
  const { open, closeModal, data } = props;
  const [servicesData, setServicesData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Update servicesData when data prop changes
  useEffect(() => {
    if (data) {
      setServicesData(data || []); // Assuming data has a services field
      console.log(data, "dataa");
    }
  }, [data]);

  return (
    <Modal open={open} onClose={() => {}}>
      <Box sx={style}>
        <CloseIcon
          onClick={() => {
            closeModal();
          }}
          style={{
            position: "fixed",
            top: "10px",
            right: "20px",
            cursor: "pointer",
          }}
        />

        <div
          style={{ height: "600px", overflow: "auto", paddingBottom: "20px" }}
        >
          <Content>
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {translationsEn.vehicles.history}
            </Typography>

            <hr></hr>
            <TableContainer sx={{ maxHeight: 401, marginTop: "20px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.vehicles.typeOfService}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.vehicles.description}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.addServiceVehicle.contactPerson}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.addServiceVehicle.lastPrice}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.addServiceVehicle.validFrom}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.addServiceVehicle.validTo}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.vehicles.contactDetails}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : servicesData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        {translationsEn.table.noData}
                      </TableCell>
                    </TableRow>
                  ) : (
                    servicesData.map((service, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          {service.typeOfService}
                        </TableCell>
                        <TableCell align="left">
                          {service.description}
                        </TableCell>
                        <TableCell align="left">
                          {service.contactPerson}
                        </TableCell>
                        <TableCell align="left">{service.lastPrice}</TableCell>
                        <TableCell align="left">{service.validFrom}</TableCell>
                        <TableCell align="left">{service.validTo}</TableCell>
                        <TableCell align="left">
                          {service.contactDetails}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Content>
        </div>
      </Box>
    </Modal>
  );
}
