import { Link, useNavigate } from "react-router-dom";
import APICall from "../api/axios";
import { useAuth } from "../provider/authProvider";
import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import {
  loginPageStyle,
  boxStyle,
  logoStyle,
  separatorStyle,
  inputForms,
} from "../components/layout/login/LoginStyle";
import Logo from "./../logo.png";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import ForgotPasswordModal from "../modals/login/forgotPassword";
import { VisibilityOff, Visibility } from "@mui/icons-material";
const LoginComponent = () => {
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, loginError } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    await login(username, password, navigate);
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div style={loginPageStyle}>
      <Box sx={boxStyle}>
        <img src={Logo} alt="Logo" style={logoStyle} />
        <div style={separatorStyle} />
        <div className="login-container">
          <form onSubmit={handleLogin} className="login-form">
            <div style={inputForms} className="form-group">
              <Typography style={{ marginRight: "200px", fontSize: "15px" }}>
                {translationsEn.login.username}
              </Typography>

              <TextField
                id="outlined-basic"
                variant="outlined"
                type="name"
                value={username}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ margin: 10, width: "93%" }}
              />

              <Typography style={{ marginRight: "200px", fontSize: "15px" }}>
                {translationsEn.login.password}
              </Typography>

              <FormControl
                sx={{ m: 1 }}
                variant="outlined"
                style={{ width: "100%" }}
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={
                    {
                      margin: 10,
                      WebkitTextSecurity: showPassword ? "none" : "disc",
                    } as React.CSSProperties
                  }
                />
              </FormControl>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.primary.main,
                  color: "white",
                  ":hover": {
                    background: themeOptions.palette.primary.hover,
                  },
                }}
                type="submit"
                className="login-button"
              >
                {translationsEn.login.signIn}
              </Button>
            </div>
          </form>
          <Typography variant="body2" align="center" style={{ margin: 10 }}>
            <Link
              onClick={handleOpenModal}
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "black",
              }}
              to={""}
            >
              {translationsEn.login.forgot}
            </Link>
          </Typography>
        </div>
      </Box>

      <Grid style={{ padding: "10px" }}>
        {loginError && <Alert severity="error">{loginError}</Alert>}
      </Grid>

      <ForgotPasswordModal open={openModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default LoginComponent;
