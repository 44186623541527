import {
  Modal,
  Box,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { useAlert } from "../../utils/alertContext";
import translationsEn from "../../i18n/translations_en";
import { getEmployeeContract } from "../../api/requestsApi";
import dayjs from "dayjs";
import { Add } from "@mui/icons-material";
import CancelContract from "../../modals/employeeManagement/cancelContract";
import NewContractEmployee from "../../modals/employeeManagement/newContractEmployee";
import themeOptions from "../../style/componentsStyle";
import { getStatuses } from "../../api/employeeApi";
type RequestStatusName = "student" | "inactive" | "full_time" | "part_time";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

const Content = styled("div")({
  width: "900px",
  margin: "auto",
  padding: "30px 10px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  marginTop: "30px",
});

export default function ContractOverview(props: any) {
  const { open, handleClose, employeeId } = props;
  const [employeeContract, setEmployeeContract] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshListTrigger, setRefreshListTrigger] = useState(true);
  const [allStatuses, setAllStatuses] = useState<any>([]);
  useEffect(() => {
    getStatuses()
      .then((response) => setAllStatuses(response.data))
      .catch((error) => console.error("Failed to fetch teams:", error));
  }, []);
  useEffect(() => {
    const fetchContractData = async () => {
      try {
        if (employeeId) {
          const employeeContract = await getEmployeeContract(
            Number(employeeId)
          );
          setEmployeeContract(employeeContract.data);
        } else {
          console.error("User ID is undefined");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchContractData();
  }, [employeeId, refreshListTrigger]);

  const [modalStates, setModalStates] = useState({
    newContract: false,
    cancelContract: false,
  });

  const handleOpenModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: keyof typeof modalStates) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  return (
    <Modal open={open} onClose={() => {}}>
      <Box sx={style}>
        <CloseIcon
          onClick={() => {
            handleClose();
          }}
          style={{
            position: "fixed",
            top: "10px",
            right: "20px",
            cursor: "pointer",
          }}
        />

        <div
          style={{ height: "600px", overflow: "auto", paddingBottom: "20px" }}
        >
          <Content>
            <Grid
              sx={{
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid></Grid>
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: themeOptions.palette.primary.main,
                    ":hover": {
                      backgroundColor: themeOptions.palette.primary.hover,
                    },
                    marginRight: "10px",
                    borderRadius: "100px",
                  }}
                  onClick={() => {
                    handleOpenModal("newContract");
                  }}
                >
                  <Add />
                </Button>
                <Button
                  sx={{
                    backgroundColor: themeOptions.palette.primary.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.primary.hover,
                    },
                  }}
                  onClick={() => handleOpenModal("cancelContract")}
                >
                  {translationsEn.buttons.cancelContract}
                </Button>
              </Grid>
            </Grid>
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {translationsEn.editUser.contract}
            </Typography>

            <hr></hr>
            <TableContainer sx={{ maxHeight: 401, marginTop: "20px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.editUser.startDate}:{" "}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.editUser.endDate}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.editUser.shiftDuration}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.editUser.vacationDays}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.editUser.probatioDate}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.editUser.employeeStatus}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : employeeContract.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        {translationsEn.table.noData}
                      </TableCell>
                    </TableRow>
                  ) : (
                    employeeContract.map((contract: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          {dayjs(contract.startDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          {contract.endDate
                            ? dayjs(contract.endDate).format("DD-MM-YYYY")
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {contract.shiftDuration}
                        </TableCell>
                        <TableCell align="left">
                          {contract.vacationDays}
                        </TableCell>
                        <TableCell align="left">
                          {contract.probationExpirationDate
                            ? dayjs(contract.probationExpirationDate).format(
                                "DD-MM-YYYY"
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {translationsEn.statusType[
                            allStatuses.find(
                              (status: any) =>
                                status.id === contract.employeeStatusId
                            )?.status as RequestStatusName
                          ] || contract.employeeStatusId}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Content>
          <NewContractEmployee
            open={modalStates.newContract}
            employeeId={employeeId}
            handleClose={() => handleCloseModal("newContract")}
            refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
          />
          <CancelContract
            open={modalStates.cancelContract}
            handleClose={() => handleCloseModal("cancelContract")}
            employeeId={employeeId}
            refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
          />
        </div>
      </Box>
    </Modal>
  );
}
