import axios from "axios";

let config;

async function loadConfig() {
  const response = await fetch(process.env.PUBLIC_URL + "/config.json");
  config = await response.json();
}

await loadConfig();

const BASE_URL = config.apiHost;

const APICall = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

APICall.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default APICall;
