import {
  Modal,
  Box,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { useAlert } from "../../utils/alertContext";
import translationsEn from "../../i18n/translations_en";
import { getChangelogForVehicle } from "../../api/vehiclesApi";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

const Content = styled("div")({
  width: "900px",
  margin: "auto",
  padding: "30px 10px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  marginTop: "30px",
});

export default function ChangeLogModal(props: any) {
  const { open, closeModal, id } = props;
  const [changelog, setChangelog] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { showAlert } = useAlert();
  // Update servicesData when data prop changes
  useEffect(() => {
    if (id) {
      setLoading(true); // Set loading state to true before API call
      getChangelogForVehicle(id)
        .then((changelogResponse) => {
          setChangelog(changelogResponse.data);
        })
        .catch((error) => {
          showAlert("Failed to fetch changelog", "error");
        })
        .finally(() => {
          setLoading(false); // Set loading state to false after API call
        });
    }
  }, [id]); // Dependency array includes id

  return (
    <Modal open={open} onClose={() => {}}>
      <Box sx={style}>
        <CloseIcon
          onClick={() => {
            closeModal();
          }}
          style={{
            position: "fixed",
            top: "10px",
            right: "20px",
            cursor: "pointer",
          }}
        />

        <div
          style={{ height: "600px", overflow: "auto", paddingBottom: "20px" }}
        >
          <Content>
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {translationsEn.vehicles.history}
            </Typography>

            <hr></hr>
            <TableContainer sx={{ maxHeight: 401, marginTop: "20px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.vehicles.changedBy}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.vehicles.oldKm}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.vehicles.newKm}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {translationsEn.vehicles.timeOfChange}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : changelog.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        {translationsEn.table.noData}
                      </TableCell>
                    </TableRow>
                  ) : (
                    changelog.map((changeLog, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          {changeLog.employeeDto.firstName +
                            " " +
                            changeLog.employeeDto.lastName}
                        </TableCell>
                        <TableCell align="left">
                          {changeLog.oldKmValue}
                        </TableCell>
                        <TableCell align="left">
                          {changeLog.newKmValue}
                        </TableCell>
                        <TableCell align="left">
                          {changeLog.changeTime}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Content>
        </div>
      </Box>
    </Modal>
  );
}
