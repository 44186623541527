import {
  Modal,
  Box,
  styled,
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import translationsEn from "../../i18n/translations_en";
import { useFormik } from "formik";
import * as Yup from "yup";
import themeOptions from "../../style/componentsStyle";
import { useEffect, useState } from "react";
import { addCost } from "../../api/travelRequestApi";
import { useAlert } from "../../utils/alertContext";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { createAddOns } from "../../api/vehiclesApi";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

const Title = styled("h3")({
  margin: "auto",
});

const HorizontalLine = styled("hr")({
  display: "block",
  height: "1px",
  border: "0",
  borderTop: "2px solid #ccc",
  margin: "1em 0",
  padding: "0",
  width: "100%",
});

const Content = styled("div")({
  width: "900px",
  margin: "auto",
  padding: "30px 0px",
});

const validationSchema = Yup.object({
  typeOfService: Yup.string().required("Type of service is required"),
  description: Yup.string().required("Description is required"),
  contactPerson: Yup.string().required("Contact person is required"),
  contactDetails: Yup.string().required("Contact details is required"),
  lastPrice: Yup.number().required("Last price is required"),
  validFrom: Yup.string().required("Valid from is required"),
  validTo: Yup.string().required("Valid to is required"),
});

const initialValues = {
  typeOfService: "",
  description: "",
  contactPerson: "",
  contactDetails: "",
  lastPrice: 0,
  validFrom: "",
  validTo: "",
  vehicleId: 0,
};

export default function AddVehicleService(props?: any) {
  const { open, closeModal, vehicleId } = props;
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    formik.setFieldValue("vehicleId", vehicleId);
  }, [vehicleId]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      console.log(values);
      console.log(vehicleId);
      setLoading(true);
      createAddOns(values)
        .then(() => {
          closeModal();
          showAlert("Addons added successfully!", "success");
        })
        .catch((error) => {
          showAlert(error, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  return (
    <Modal open={open} onClose={() => {}}>
      <Box sx={style}>
        <Title>{translationsEn.addServiceVehicle.name}</Title>
        <HorizontalLine />
        <Content>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(
                    formik.touched.typeOfService && formik.errors.typeOfService
                  )}
                >
                  <InputLabel id="demo-simple-select-label">
                    {translationsEn.addServiceVehicle.typeOfService}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label={translationsEn.addServiceVehicle.typeOfService}
                    value={formik.values.typeOfService}
                    onChange={(e: any) => {
                      formik.setFieldValue("typeOfService", e.target.value);
                    }}
                  >
                    <MenuItem value="Registracija">Registracija</MenuItem>
                    <MenuItem value="Mali servis">Mali servis</MenuItem>
                    <MenuItem value="Veliki servis">Veliki servis</MenuItem>
                  </Select>
                  {formik.touched.typeOfService &&
                    formik.errors.typeOfService && (
                      <FormHelperText>
                        {formik.errors.typeOfService as string}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label={translationsEn.addServiceVehicle.description}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.description && formik.errors.description
                  )}
                  helperText={
                    formik.touched.description &&
                    formik.errors.description &&
                    (formik.errors.description as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label={translationsEn.addServiceVehicle.contactPerson}
                  name="contactPerson"
                  value={formik.values.contactPerson}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.contactPerson && formik.errors.contactPerson
                  )}
                  helperText={
                    formik.touched.contactPerson &&
                    formik.errors.contactPerson &&
                    (formik.errors.contactPerson as string)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label={translationsEn.addServiceVehicle.contactDetails}
                  name="contactDetails"
                  value={formik.values.contactDetails}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.contactDetails &&
                      formik.errors.contactDetails
                  )}
                  helperText={
                    formik.touched.contactDetails &&
                    formik.errors.contactDetails &&
                    (formik.errors.contactDetails as string)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} style={{ display: "flex" }}>
                <FormControl style={{ width: "100%" }} margin="dense">
                  <DatePicker
                    label={translationsEn.addServiceVehicle.validFrom}
                    value={
                      formik.values.validFrom
                        ? dayjs(formik.values.validFrom)
                        : null
                    }
                    onChange={(date) =>
                      formik.setFieldValue(
                        "validFrom",
                        date ? date.format("YYYY-MM-DD") : null // Change the format here
                      )
                    }
                    slotProps={{
                      textField: {
                        helperText:
                          formik.touched.validFrom && formik.errors.validFrom,
                        error: Boolean(
                          formik.touched.validFrom && formik.errors.validFrom
                        ),
                      },
                    }}
                    sx={{ width: "90%" }}
                  />
                </FormControl>
                <FormControl style={{ width: "100%" }} margin="dense">
                  <DatePicker
                    label={translationsEn.addServiceVehicle.validTo}
                    value={
                      formik.values.validTo
                        ? dayjs(formik.values.validTo)
                        : null
                    }
                    onChange={(date) =>
                      formik.setFieldValue(
                        "validTo",
                        date ? date.format("YYYY-MM-DD") : null // Change the format here
                      )
                    }
                    slotProps={{
                      textField: {
                        helperText:
                          formik.touched.validTo && formik.errors.validTo,
                        error: Boolean(
                          formik.touched.validTo && formik.errors.validTo
                        ),
                      },
                    }}
                    sx={{ width: "90%" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  margin="dense"
                  label={translationsEn.addServiceVehicle.lastPrice}
                  name="lastPrice"
                  value={formik.values.lastPrice}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.lastPrice && formik.errors.lastPrice
                  )}
                  helperText={
                    formik.touched.lastPrice &&
                    formik.errors.lastPrice &&
                    (formik.errors.lastPrice as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    color: themeOptions.palette.primary.main,
                    borderColor: themeOptions.palette.primary.main,
                    textTransform: "none",
                    marginTop: "20px",
                  }}
                  disabled={loading}
                  onClick={() => closeModal()}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    translationsEn.createTravelRequests.cancel
                  )}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: themeOptions.palette.primary.main,
                    textTransform: "none",
                    marginTop: "20px",
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    translationsEn.createTravelRequests.submit
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Content>
      </Box>
    </Modal>
  );
}
