import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  styled,
} from "@mui/material";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  handleAttachFiles,
  handleDeleteFile,
  handleDownloadFile,
} from "./../utils/filesHandler";
import { getAllEmployees } from "../api/employeeApi";
import { getAllCompanies } from "../api/overviewApi";
import { createWorkOrders, uploadFile } from "../api/workOrders";
import dayjs from "dayjs";
import { useAlert } from "../utils/alertContext";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function NewWorkOrders() {
  const [attachedDocuments, setAttachedDocuments] = useState<File[]>([]);
  const [allCompanies, setAllCompanies] = useState<any>([]);
  const [allEmployees, setAllEmployees] = useState<any[]>([]);
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  useEffect(() => {
    getAllCompanies()
      .then((response) => setAllCompanies(response))
      .catch((error) => console.error("Failed to fetch companies:", error));
  }, []);

  useEffect(() => {
    const filter = { companyIds: [], employmentStatusId: 1 };
    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch employees:", error));
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
      reporter: "",
      companyType: "",
      companyName: "",
      assignedUser: "",
      location: "",
      deadlineDate: null,
      deadlineTime: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(translationsEn.validation.required),
      reporter: Yup.string().required(translationsEn.validation.required),
      companyType: Yup.string().required(translationsEn.validation.required),
      companyName: Yup.string().required(translationsEn.validation.required),
      assignedUser: Yup.string().required(translationsEn.validation.required),
      location: Yup.string().required(translationsEn.validation.required),
      deadlineDate: Yup.date().required(translationsEn.validation.required),
      deadlineTime: Yup.string().required(translationsEn.validation.required),
      description: Yup.string(),
    }),

    onSubmit: async (values) => {
      try {
        const formatDate = (date: Date | null) =>
          date ? dayjs(date).format("YYYY-MM-DD") : null;

        const payload = {
          title: values.title,
          companyType: values.companyType,
          deadlineDate: formatDate(values.deadlineDate),
          deadlineTime: values.deadlineTime,
          location: values.location,
          description: values.description,
          reporterId: parseInt(values.reporter, 10),
          assignedId: parseInt(values.assignedUser, 10),
          companyId: parseInt(values.companyName, 10),
        };

        const workOrderData = await createWorkOrders(payload);
        const workOrderId = workOrderData.data.id;

        if (attachedDocuments)
          attachedDocuments.forEach((file) => {
            uploadFile(workOrderId, file)
              .then((response) => {
                showAlert("File uploaded successfully!", "success");
              })
              .catch((error) => {
                showAlert("File upload error!", "error");
              })
              .finally(() => {
                setAttachedDocuments([]);
              });
          });

        showAlert(`${translationsEn.workOrders.success}`, "success");
        navigate("/workOrders");
      } catch (error) {
        console.error("Error creating work order:", error);
        showAlert(`${translationsEn.workOrders.error}`, "error");
      }
    },
  });

  return (
    <>
      <Typography
        style={{
          margin: "40px",
          fontSize: "30px",
          fontWeight: "bold",
          color: "#555555",
        }}
      >
        {translationsEn.workOrders.newWorkOrders}
      </Typography>

      <Paper
        style={{
          padding: "20px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Grid></Grid>
          <Button
            variant="contained"
            startIcon={<AttachFileIcon />}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
              borderRadius: "15px",
            }}
            component="label"
          >
            Attach document
            <VisuallyHiddenInput
              onChange={(event) =>
                handleAttachFiles(
                  event,
                  attachedDocuments,
                  setAttachedDocuments
                )
              }
              type="file"
            />
          </Button>
        </Grid>

        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={3}
            style={{ maxWidth: "1500px", paddingLeft: "100px" }}
          >
            <Grid item xs={6}>
              <TextField
                label={translationsEn.workOrders.title}
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                style={{ borderRadius: "15px" }}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{translationsEn.workOrders.reporter}</InputLabel>
                <Select
                  label={translationsEn.workOrders.reporter}
                  value={formik.values.reporter}
                  onChange={formik.handleChange}
                  name="reporter"
                  error={
                    formik.touched.reporter && Boolean(formik.errors.reporter)
                  }
                >
                  {allEmployees.length > 0 ? (
                    allEmployees.map((employee: any) => (
                      <MenuItem
                        key={employee.employeeDto.id}
                        value={employee.employeeDto.id}
                      >
                        {employee.employeeDto.firstName +
                          " " +
                          employee.employeeDto.lastName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No employees available</MenuItem>
                  )}
                </Select>

                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {formik.touched.reporter && formik.errors.reporter}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth style={{ borderRadius: "15px" }}>
                <InputLabel id="companyType-label">
                  {translationsEn.workOrders.companyType}
                </InputLabel>
                <Select
                  label={translationsEn.workOrders.companyType}
                  id="companyType"
                  name="companyType"
                  value={formik.values.companyType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.companyType &&
                    Boolean(formik.errors.companyType)
                  }
                >
                  <MenuItem value="Internal">Internal</MenuItem>
                  <MenuItem value="External">External</MenuItem>
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {formik.touched.companyType && formik.errors.companyType}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth style={{ borderRadius: "15px" }}>
                <InputLabel id="companyName-label">
                  {translationsEn.workOrders.companyName}
                </InputLabel>
                <Select
                  label={translationsEn.workOrders.companyName}
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                >
                  {allCompanies
                    .filter((company: any) =>
                      formik.values.companyType === "Internal"
                        ? company.internal === true
                        : formik.values.companyType === "External"
                        ? company.internal === false
                        : true
                    )
                    .map((company: any) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {formik.touched.companyName && formik.errors.companyName}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>
                  {translationsEn.workOrders.assignedUser}
                </InputLabel>

                <Select
                  label={translationsEn.workOrders.assignedUser}
                  value={formik.values.assignedUser}
                  onChange={formik.handleChange}
                  name="assignedUser"
                  error={
                    formik.touched.assignedUser &&
                    Boolean(formik.errors.assignedUser)
                  }
                >
                  {allEmployees.length > 0 ? (
                    allEmployees.map((employee: any) => (
                      <MenuItem
                        key={employee.employeeDto.id}
                        value={employee.employeeDto.id}
                      >
                        {employee.employeeDto.firstName +
                          " " +
                          employee.employeeDto.lastName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No employees available</MenuItem>
                  )}
                </Select>

                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {formik.touched.assignedUser && formik.errors.assignedUser}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={translationsEn.workOrders.location}
                id="location"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                style={{ borderRadius: "15px" }}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
              />
            </Grid>

            <Grid item xs={6}>
              <DatePicker
                label={translationsEn.workOrders.deadline}
                value={formik.values.deadlineDate}
                onChange={(date: any) =>
                  formik.setFieldValue("deadlineDate", date)
                }
                sx={{ width: "100%" }}
                format="DD-MM-YYYY"
                slotProps={{
                  textField: {
                    helperText:
                      formik.touched.deadlineDate &&
                      formik.errors.deadlineDate &&
                      (formik.errors.deadlineDate as string),
                    error: Boolean(
                      formik.touched.deadlineDate && formik.errors.deadlineDate
                    ),
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TimePicker
                label={translationsEn.workOrders.time}
                value={
                  formik.values.deadlineTime
                    ? dayjs(formik.values.deadlineTime, "HH:mm")
                    : null
                }
                onChange={(time: any) =>
                  formik.setFieldValue(
                    "deadlineTime",
                    time ? time.format("HH:mm") : ""
                  )
                }
                ampm={false}
                slotProps={{
                  textField: {
                    helperText:
                      formik.touched.deadlineTime &&
                      formik.errors.deadlineTime &&
                      (formik.errors.deadlineTime as string),
                    error: Boolean(
                      formik.touched.deadlineTime && formik.errors.deadlineTime
                    ),
                  },
                }}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={translationsEn.workOrders.description}
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                multiline
                rows={4}
                style={{ borderRadius: "15px" }}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Grid>
            <Grid container spacing={2} style={{ margin: "10px" }}>
              {attachedDocuments.map((doc, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper
                    style={{
                      padding: "5px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <Typography variant="body2" style={{ flexGrow: 1 }}>
                      {doc.name}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} style={{ display: "flex", width: "100%" }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ width: "100%" }}
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: themeOptions.palette.primary.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.primary.hover,
                    },
                  }}
                >
                  {translationsEn.buttons.confirm}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ width: "100%" }}
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: themeOptions.palette.primary.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.primary.hover,
                    },
                  }}
                  onClick={() => navigate("/workOrders")}
                >
                  {translationsEn.buttons.cancel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
