import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormHelperText,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useAlert } from "../../utils/alertContext";
import {
  createVehicle,
  updatedVehicle,
  uploadFiles,
} from "../../api/vehiclesApi";
import { handleAttachFiles } from "../../utils/filesHandler";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddVehicleService from "./addServiceModal";
import { getAllEmployees } from "../../api/employeeApi";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export const initialVehicleValues = (vehicleData: any) => ({
  id: vehicleData ? vehicleData.id : 0,
  vehicleName: vehicleData ? vehicleData.vehicleName : "",
  licencePlateNo: vehicleData ? vehicleData.licencePlateNo : "",
  firstRegistrationYear: vehicleData ? vehicleData.firstRegistrationYear : "",
  vehicleOwner: vehicleData ? vehicleData.vehicleOwner : "",
  vehicleUserId: vehicleData ? vehicleData.vehicleUserId : 0,
  brand: vehicleData ? vehicleData.brand : "",
  homologationType: vehicleData ? vehicleData.homologationType : "",
  commercialName: vehicleData ? vehicleData.commercialName : "",
  vinNo: vehicleData ? vehicleData.vinNo : "",
  maximumAuthorizedMass: vehicleData ? vehicleData.maximumAuthorizedMass : "",
  technicalMaxAllowedMass: vehicleData
    ? vehicleData.technicalMaxAllowedMass
    : 0,
  curbWeight: vehicleData ? vehicleData.curbWeight : "",
  lastRegistrationYear: vehicleData ? vehicleData.lastRegistrationYear : 0,
  category: vehicleData ? vehicleData.category : "",
  numberOfAxles: vehicleData ? vehicleData.numberOfAxles : 0,
  displacement: vehicleData ? vehicleData.displacement : 0,
  engineHorsepower: vehicleData ? vehicleData.engineHorsepower : 0,
  fuelType: vehicleData ? vehicleData.fuelType : "",
  numberOfSeats: vehicleData ? vehicleData.numberOfSeats : 0,
  standingCapacity: vehicleData ? vehicleData.standingCapacity : 0,
  maxSpeed: vehicleData ? vehicleData.maxSpeed : 0,
  stationaryNoise: vehicleData ? vehicleData.stationaryNoise : 0,
  co2Emission: vehicleData ? vehicleData.co2Emission : 0,
  ecoCategory: vehicleData ? vehicleData.ecoCategory : 0,
  ownerOIB: vehicleData ? vehicleData.ownerOIB : "",
  bodyType: vehicleData ? vehicleData.bodyType : "",
  purpose: vehicleData ? vehicleData.purpose : "",
  firstRegistrationInCountry: vehicleData
    ? vehicleData.firstRegistrationInCountry
    : "",
  permissiblePayload: vehicleData ? vehicleData.permissiblePayload : 0,
  length: vehicleData ? vehicleData.length : 0,
  width: vehicleData ? vehicleData.width : 0,
  height: vehicleData ? vehicleData.height : 0,
  tyreSize: vehicleData ? vehicleData.tyreSize : 0,
  model: vehicleData ? vehicleData.model : "",
  kmOnInputDay: vehicleData ? vehicleData.kmOnInputDay : 0,
  status: vehicleData ? vehicleData.status : "free",
  kmOnDay: vehicleData ? vehicleData.kmOnDay : 0,
  kmDate: vehicleData ? vehicleData.kmDate : "",
});

export default function NewVehicles() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [addService, setAddService] = useState<boolean>(false);
  const closeAddServiceModal = () => {
    setAddService(false);
  };
  const location = useLocation();
  const { vehicleId } = location.state || {};
  console.log(vehicleId, "id");
  const [attachedDocuments, setAttachedDocuments] = useState<File[]>([]);
  const { vehicleData, isEditing } = location.state || {};
  const [employeeList, setEmployeeList] = useState<any[]>([]);

  const initialValues = initialVehicleValues(vehicleData);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      vehicleName: Yup.string().required(translationsEn.validation.required),
      licencePlateNo: Yup.string().required(translationsEn.validation.required),
    }),

    onSubmit: async (values) => {
      try {
        console.log("Attached documents at submit:", attachedDocuments);

        // Prepare the payload
        const payload = {
          vehicleName: values.vehicleName,
          licencePlateNo: values.licencePlateNo,
          firstRegistrationYear: parseInt(values.firstRegistrationYear, 10),
          vehicleOwner: values.vehicleOwner,
          vehicleUserId: parseInt(values.vehicleUserId, 10),
          technicalMaxAllowedMass: values.technicalMaxAllowedMass,
          brand: values.brand,
          homologationType: values.homologationType,
          commercialName: values.commercialName,
          vinNo: values.vinNo,
          maximumAuthorizedMass: parseInt(values.maximumAuthorizedMass, 10),
          curbWeight: parseInt(values.curbWeight, 10),
          lastRegistrationYear: values.lastRegistrationYear,
          category: values.category,
          numberOfAxles: values.numberOfAxles,
          displacement: values.displacement,
          engineHorsepower: values.engineHorsepower,
          fuelType: values.fuelType,
          numberOfSeats: values.numberOfSeats,
          standingCapacity: values.standingCapacity,
          maxSpeed: values.maxSpeed,
          stationaryNoise: values.stationaryNoise,
          co2Emission: values.co2Emission,
          ecoCategory: values.ecoCategory,
          ownerOIB: values.ownerOIB,
          bodyType: values.bodyType,
          purpose: values.purpose,
          firstRegistrationInCountry: values.firstRegistrationInCountry,
          permissiblePayload: values.permissiblePayload,
          length: values.length,
          width: values.width,
          height: values.height,
          tyreSize: values.tyreSize,
          model: values.model,
          kmOnInputDay: values.kmOnInputDay,
          ...(values.id && { status: values.status }),
          ...(values.id && { kmOnDay: values.kmOnDay }),
          ...(values.id && { kmDate: values.kmDate }),
        };

        let vehicleResponse;

        if (values.id) {
          vehicleResponse = await updatedVehicle({
            id: values.id,
            ...payload,
          });
        } else {
          vehicleResponse = await createVehicle(payload);
        }

        showAlert(`${translationsEn.vehicles.success}`, "success");

        if (attachedDocuments && attachedDocuments.length > 0 && vehicleId) {
          await Promise.all(
            attachedDocuments.map(async (file) => {
              try {
                await uploadFiles(vehicleData.id, file);
                showAlert("File uploaded successfully!", "success");
              } catch {
                showAlert("File upload error!", "error");
              }
            })
          );
          console.log("Attached documents:", attachedDocuments);

          setAttachedDocuments([]);
        }

        navigate("/vehicles");
      } catch (error) {
        console.error("Error saving vehicle:", error);
        showAlert("Error saving vehicle", "error");
      }
    },
  });

  const fetchEmployees = () => {
    const filter = { companyIds: [], employmentStatusId: 1 };

    getAllEmployees(filter)
      .then((response) => {
        setEmployeeList(response.data);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleClose = () => {
    formik.resetForm();
    navigate("/vehicles");
  };
  console.log(formik.values.vehicleUserId, "vehicle user id");
  return (
    <>
      <Grid style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          style={{
            margin: "40px",
            fontSize: "30px",
            fontWeight: "bold",
            color: "#555555",
          }}
        >
          {isEditing
            ? translationsEn.vehicles.editVehicle
            : translationsEn.vehicles.newVehicle}
        </Typography>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <Button
              variant="contained"
              startIcon={<AttachFileIcon />}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
                borderRadius: "15px",
                maxHeight: "30px",
                width: "100%",
              }}
              component="label"
            >
              Attach document
              <VisuallyHiddenInput
                onChange={(event) =>
                  handleAttachFiles(
                    event,
                    attachedDocuments,
                    setAttachedDocuments
                  )
                }
                type="file"
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Paper
        style={{
          padding: "20px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} style={{ maxWidth: "1500px" }}>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.vehicleName}
                id="vehicleName"
                name="vehicleName"
                value={formik.values.vehicleName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.vehicleName &&
                  Boolean(formik.errors.vehicleName)
                }
              />
            </Grid>
            {isEditing && (
              <Grid item xs={6}>
                <TextField
                  label={translationsEn.vehicles.status}
                  id="status"
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  error={formik.touched.status && Boolean(formik.errors.status)}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.licencePlateNo}
                id="licencePlateNo"
                name="licencePlateNo"
                value={formik.values.licencePlateNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.licencePlateNo &&
                  Boolean(formik.errors.licencePlateNo)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.firstRegistrationYear}
                id="firstRegistrationYear"
                name="firstRegistrationYear"
                value={formik.values.firstRegistrationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.firstRegistrationYear &&
                  Boolean(formik.errors.firstRegistrationYear)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.vehicleOwner}
                id="vehicleOwner"
                name="vehicleOwner"
                value={formik.values.vehicleOwner}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.vehicleOwner &&
                  Boolean(formik.errors.vehicleOwner)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="vehicleUserId-label">
                  {translationsEn.vehicles.vehicleUser}
                </InputLabel>
                <Select
                  labelId="vehicleUserId-label"
                  id="vehicleUserId"
                  name="vehicleUserId"
                  value={formik.values.vehicleUserId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.vehicleUserId &&
                    Boolean(formik.errors.vehicleUserId)
                  }
                >
                  {employeeList?.map((employee) => (
                    <MenuItem
                      key={employee.employeeDto.id}
                      value={employee.employeeDto.id}
                    >
                      {`${employee.employeeDto.firstName} ${employee.employeeDto.lastName}`}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.brand}
                id="brand"
                name="brand"
                value={formik.values.brand}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.brand && Boolean(formik.errors.brand)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(
                  formik.touched.homologationType &&
                    formik.errors.homologationType
                )}
              >
                <InputLabel id="demo-simple-select-label">
                  Homologation type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Homologation type"
                  value={formik.values.homologationType}
                  onChange={(e: any) => {
                    formik.setFieldValue("homologationType", e.target.value);
                  }}
                >
                  <MenuItem value="Type1">Type 1</MenuItem>
                  <MenuItem value="Type2">Type 2</MenuItem>
                  <MenuItem value="Type3">Type 3</MenuItem>
                  <MenuItem value="Type4">Type 4</MenuItem>
                </Select>
                {formik.touched.homologationType &&
                  formik.errors.homologationType && (
                    <FormHelperText>
                      {formik.errors.homologationType as string}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.commercial}
                id="commercialName"
                name="commercialName"
                value={formik.values.commercialName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.commercialName &&
                  Boolean(formik.errors.commercialName)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.vinNo}
                id="vinNo"
                name="vinNo"
                value={formik.values.vinNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.vinNo && Boolean(formik.errors.vinNo)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.tehnical}
                id="technicalMaxAllowedMass"
                name="technicalMaxAllowedMass"
                value={formik.values.technicalMaxAllowedMass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.technicalMaxAllowedMass &&
                  Boolean(formik.errors.technicalMaxAllowedMass)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.maximumAuthorizedMass}
                id="maximumAuthorizedMass"
                name="maximumAuthorizedMass"
                value={formik.values.maximumAuthorizedMass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.maximumAuthorizedMass &&
                  Boolean(formik.errors.maximumAuthorizedMass)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.curb}
                id="curbWeight"
                name="curbWeight"
                value={formik.values.curbWeight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.curbWeight && Boolean(formik.errors.curbWeight)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.lastYearRegistration}
                id="lastRegistrationYear"
                name="lastRegistrationYear"
                value={formik.values.lastRegistrationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.lastRegistrationYear &&
                  Boolean(formik.errors.lastRegistrationYear)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.category}
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(
                  formik.touched.numberOfAxles && formik.errors.numberOfAxles
                )}
              >
                <InputLabel>Number of axles</InputLabel>
                <Select
                  label="Number of axles"
                  value={formik.values.numberOfAxles}
                  onChange={(e: any) => {
                    formik.setFieldValue("numberOfAxles", e.target.value);
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
                {formik.touched.numberOfAxles &&
                  formik.errors.numberOfAxles && (
                    <FormHelperText>
                      {formik.errors.numberOfAxles as string}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.displacement}
                id="displacement"
                name="displacement"
                value={formik.values.displacement}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.displacement &&
                  Boolean(formik.errors.displacement)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.engineHorsepower}
                id="engineHorsepower"
                name="engineHorsepower"
                value={formik.values.engineHorsepower}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.engineHorsepower &&
                  Boolean(formik.errors.engineHorsepower)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.fuelType}
                id="fuelType"
                name="fuelType"
                value={formik.values.fuelType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.fuelType && Boolean(formik.errors.fuelType)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.numberOfSeats}
                id="numberOfSeats"
                name="numberOfSeats"
                value={formik.values.numberOfSeats}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.numberOfSeats &&
                  Boolean(formik.errors.numberOfSeats)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.standingCapacity}
                id="standingCapacity"
                name="standingCapacity"
                value={formik.values.standingCapacity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.standingCapacity &&
                  Boolean(formik.errors.standingCapacity)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.maximumSpeed}
                id="maxSpeed"
                name="maxSpeed"
                value={formik.values.maxSpeed}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.maxSpeed && Boolean(formik.errors.maxSpeed)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.stationaryNoise}
                id="stationaryNoise"
                name="stationaryNoise"
                value={formik.values.stationaryNoise}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.stationaryNoise &&
                  Boolean(formik.errors.stationaryNoise)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.co2}
                id="co2Emission"
                name="co2Emission"
                value={formik.values.co2Emission}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.co2Emission &&
                  Boolean(formik.errors.co2Emission)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.ecoCategory}
                id="ecoCategory"
                name="ecoCategory"
                value={formik.values.ecoCategory}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.ecoCategory &&
                  Boolean(formik.errors.ecoCategory)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.ownerOIB}
                id="ownerOIB"
                name="ownerOIB"
                value={formik.values.ownerOIB}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.ownerOIB && Boolean(formik.errors.ownerOIB)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.bodyType}
                id="bodyType"
                name="bodyType"
                value={formik.values.bodyType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.bodyType && Boolean(formik.errors.bodyType)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.purpose}
                id="purpose"
                name="purpose"
                value={formik.values.purpose}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.purpose && Boolean(formik.errors.purpose)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.firstRegistrationInCountry}
                id="firstRegistrationInCountry"
                name="firstRegistrationInCountry"
                value={formik.values.firstRegistrationInCountry}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.firstRegistrationInCountry &&
                  Boolean(formik.errors.firstRegistrationInCountry)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.permissiblePayload}
                id="permissiblePayload"
                name="permissiblePayload"
                value={formik.values.permissiblePayload}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.permissiblePayload &&
                  Boolean(formik.errors.permissiblePayload)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.length}
                id="length"
                name="length"
                value={formik.values.length}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.length && Boolean(formik.errors.length)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.width}
                id="width"
                name="width"
                value={formik.values.width}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.width && Boolean(formik.errors.width)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.height}
                id="height"
                name="height"
                value={formik.values.height}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.height && Boolean(formik.errors.height)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.tireSize}
                id="tyreSize"
                name="tyreSize"
                value={formik.values.tyreSize}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.tyreSize && Boolean(formik.errors.tyreSize)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.make}
                id="model"
                name="model"
                value={formik.values.model}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.model && Boolean(formik.errors.model)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.KmOnInput}
                id="kmOnInputDay"
                name="kmOnInputDay"
                value={formik.values.kmOnInputDay}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.kmOnInputDay &&
                  Boolean(formik.errors.kmOnInputDay)
                }
              />
            </Grid>
            {isEditing && (
              <Grid item xs={6} style={{ display: "flex" }}>
                <Grid item xs={3}>
                  <TextField
                    label={translationsEn.vehicles.kmOnDay}
                    id="kmOnDay"
                    name="kmOnDay"
                    value={formik.values.kmOnDay}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    error={
                      formik.touched.kmOnDay && Boolean(formik.errors.kmOnDay)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl style={{ width: "100%", marginLeft: "30px" }}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label="Date"
                      value={
                        formik.values.kmDate ? dayjs(formik.values.kmDate) : ""
                      }
                      onChange={(date) => formik.setFieldValue("kmDate", date)}
                      slotProps={{
                        textField: {
                          helperText:
                            formik.touched.kmDate &&
                            formik.errors.kmDate &&
                            (formik.errors.kmDate as string),
                          error: Boolean(
                            formik.touched.kmDate && formik.errors.kmDate
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                {translationsEn.workOrders.document}
              </Typography>
              {attachedDocuments.map((file, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item>
                    <Typography>{file.name}</Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{
                    borderRadius: "15px",
                    width: "98%",
                    backgroundColor: themeOptions.palette.primary.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.primary.hover,
                    },
                  }}
                >
                  {translationsEn.buttons.confirm}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{ borderRadius: "15px", width: "98%" }}
                  onClick={() => handleClose()}
                >
                  {translationsEn.buttons.cancel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <AddVehicleService
          open={addService}
          closeModal={closeAddServiceModal}
          vehicleId={vehicleId}
        />
      </Paper>
    </>
  );
}
