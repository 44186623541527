import {
  Paper,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Autocomplete,
  FormControl,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { useEffect, useState } from "react";
import CreateEquipmentModal from "./createEquipmentModal";
import { getMyEquipment } from "../../api/equipmentApi";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import EquipmentPreviewModal from "./equipmentPreview";

export default function MyEquipment() {
  const [dateFrom, setDateFrom] = useState<any>("");
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedEquipment, setSelectedEquipment] = useState<any>();

  const [refresh, setRefresh] = useState<boolean>(true);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [previewItem, setPreviewItem] = useState<any>({});
  const [equipmentModal, setEquipmentModal] = useState<boolean>(false);

  const closeequipmentModal = () => {
    setSelectedEquipment(undefined);
    setEquipmentModal(false);
    setRefresh(!refresh);
  };

  const statusList = [
    { name: "IN_USE", label: "In use" },
    { name: "FREE", label: "Free" },
    { name: "DELETED", label: "Deleted" },
  ];

  useEffect(() => {
    const filter = {
      status: status ? status : null,
      usingFrom: dateFrom ? dateFrom : null,
    };
    getMyEquipment(filter)
      .then((response) => {
        setPage(0);
        setTableData(response.data.data);
      })
      .catch((error) => console.error("Failed to fetch employees:", error))
      .finally(() => {
        setLoading(false);
      });
  }, [status, dateFrom, refresh]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getStatusLabel = (name: string): string | undefined => {
    const status = statusList.find((item) => item.name === name);
    return status ? status.label : undefined;
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.equipment.myEquipment}
        </Typography>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FormControl
            style={{
              marginTop: "10px",
              marginRight: "10px",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Autocomplete
              options={statusList}
              getOptionLabel={(option: any) => option.label}
              onChange={(event: any, value: any) => {
                setStatus(value?.name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translationsEn.equipment.equipmentStatus}
                />
              )}
              sx={{ width: "200px" }}
            />
            <DatePicker
              label={translationsEn.allTravelRequests.dateFrom}
              format="DD/MM/YYYY"
              onChange={(date: any) => {
                if (date) {
                  const selectedDate = new Date(date);
                  // Set the time to 22:00:00
                  selectedDate.setHours(22, 0, 0, 0);
                  const adjustedDate = new Date(
                    selectedDate.getTime() -
                      selectedDate.getTimezoneOffset() * 60000
                  );
                  setDateFrom(adjustedDate.toISOString());
                }
              }}
              sx={{ width: "200px" }}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onAccept={(newDate) => {
                if (newDate === null) setDateFrom(newDate);
              }}
            />
          </FormControl>
        </Box>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.name}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.status}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.price}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.warranty}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.usingFrom}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.quantity}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.assignedTo}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.lastEdit}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.description}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.action}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={9} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((dataRow: any, index: any) => (
                  <TableRow
                    onClick={(event: any) => {
                      if (
                        event.currentTarget
                          .querySelector(".exclude-click")
                          .contains(event.target)
                      ) {
                        return;
                      }
                      setPreviewItem(dataRow);
                      setPreviewModal(true);
                    }}
                    key={dataRow.id}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "20px",
                    }}
                  >
                    <TableCell align="left">{dataRow.name}</TableCell>
                    <TableCell align="left">
                      {getStatusLabel(dataRow.status)}
                    </TableCell>
                    <TableCell align="left">{dataRow.price}</TableCell>
                    <TableCell align="left">
                      {dataRow.warrantyStart && dataRow.warrantyEnd
                        ? moment(dataRow.warrantyStart).format("DD.MM.YYYY") +
                          "-" +
                          moment(dataRow.warrantyEnd).format("DD.MM.YYYY")
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      {dataRow.usingFrom
                        ? moment(dataRow.usingFrom).format("DD.MM.YYYY")
                        : ""}
                    </TableCell>
                    <TableCell align="left">{dataRow.quantity || ""}</TableCell>
                    <TableCell align="left">
                      {dataRow.assignedTo
                        ? dataRow.assignedTo.firstName +
                          " " +
                          dataRow.assignedTo.lastName
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      {dataRow.updatedAt
                        ? moment(dataRow.updatedAt).format("DD.MM.YYYY")
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      {dataRow.description || ""}
                    </TableCell>
                    <TableCell align="center" className="exclude-click">
                      <Button
                        style={{ padding: "5px" }}
                        onClick={() => {
                          setSelectedEquipment(dataRow);
                          setEquipmentModal(true);
                        }}
                      >
                        <EditIcon
                          style={{
                            color: themeOptions.palette.primary.main,
                          }}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CreateEquipmentModal
          open={equipmentModal}
          closeModal={closeequipmentModal}
          selectedEquipment={selectedEquipment}
        />
        <EquipmentPreviewModal
          open={previewModal}
          closeModal={() => setPreviewModal(false)}
          data={previewItem}
        />
      </Paper>
    </div>
  );
}
