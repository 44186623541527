import APICall from "./axios";

export const getVehicles = (criteria: any) => {
  return APICall.post(`/api/vehicles/all`, criteria);
};

export const createVehicle = async ({
  vehicleName,
  licencePlateNo,
  firstRegistrationYear,
  vehicleOwner,
  vehicleUserId,
  brand,
  homologationType,
  commercialName,
  vinNo,
  maximumAuthorizedMass,
  curbWeight,
  lastRegistrationYear,
  category,
  numberOfAxles,
  displacement,
  engineHorsepower,
  fuelType,
  numberOfSeats,
  standingCapacity,
  maxSpeed,
  stationaryNoise,
  co2Emission,
  ecoCategory,
  ownerOIB,
  bodyType,
  purpose,
  firstRegistrationInCountry,
  permissiblePayload,
  length,
  width,
  height,
  tyreSize,
  model,
  kmOnInputDay,
}: {
  vehicleName: string;
  licencePlateNo: string;
  firstRegistrationYear: number;
  vehicleOwner: string;
  vehicleUserId: number;
  brand: string;
  homologationType: string;
  commercialName: string;
  vinNo: string;
  technicalMaxAllowedMass: number;
  maximumAuthorizedMass: number;
  curbWeight: number;
  lastRegistrationYear: number;
  category: string;
  numberOfAxles: number;
  displacement: number;
  engineHorsepower: number;
  fuelType: string;
  numberOfSeats: number;
  standingCapacity: number;
  maxSpeed: number;
  stationaryNoise: number;
  co2Emission: number;
  ecoCategory: number;
  ownerOIB: string;
  bodyType: string;
  purpose: string;
  firstRegistrationInCountry: string;
  permissiblePayload: number;
  length: number;
  width: number;
  height: number;
  tyreSize: number;
  model: string;
  kmOnInputDay: number;
}): Promise<any> => {
  try {
    const payload = {
      vehicleName,
      licencePlateNo,
      firstRegistrationYear,
      vehicleOwner,
      vehicleUserId,
      brand,
      homologationType,
      commercialName,
      vinNo,
      maximumAuthorizedMass,
      curbWeight,
      lastRegistrationYear,
      category,
      numberOfAxles,
      displacement,
      engineHorsepower,
      fuelType,
      numberOfSeats,
      standingCapacity,
      maxSpeed,
      stationaryNoise,
      co2Emission,
      ecoCategory,
      ownerOIB,
      bodyType,
      purpose,
      firstRegistrationInCountry,
      permissiblePayload,
      length,
      width,
      height,
      tyreSize,
      model,
      kmOnInputDay,
    };

    const response = await APICall.post<any>("/api/vehicles", payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Vehicles data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating comment`, error);
    throw error;
  }
};

export const createAddOns = async ({
  typeOfService,
  description,
  contactPerson,
  contactDetails,
  lastPrice,
  validFrom,
  validTo,
  vehicleId,
}: {
  typeOfService: string;
  description: string;
  contactPerson: string;
  contactDetails: string;
  lastPrice: number;
  validFrom: string;
  validTo: string;
  vehicleId: number;
}): Promise<any> => {
  try {
    const payload = {
      typeOfService,
      description,
      contactPerson,
      contactDetails,
      lastPrice,
      validFrom,
      validTo,
      vehicleId,
    };

    const response = await APICall.post<any>("/api/vehicles/addons", payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Addons data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating comment`, error);
    throw error;
  }
};

export const getVehicleById = async (vehicleId: number): Promise<any> => {
  try {
    const response = await APICall.get<any>(`/api/vehicles/${vehicleId}`);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Vehicles data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const getChangelogForVehicle = async (
  vehicleId: number
): Promise<any> => {
  try {
    const response = await APICall.get<any>(
      `/api/vehicles/changelog/${vehicleId}`
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Vehicles data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};
export const uploadFiles = (vehicleId: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return APICall.post(`/api/vehicles/document/${vehicleId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const deleteVehicle = (vehicleId: number) => {
  return APICall.patch(`/api/vehicles/delete/${vehicleId}`);
};

export const getDocumentByVehicleId = async (
  vehicleId: number
): Promise<any> => {
  try {
    const response = await APICall.get<any>(
      `/api/vehicles/document/${vehicleId}`
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Vehicles data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};

export const downloadDocument = async (vehicleId: any, documentId: any) => {
  try {
    const response = await APICall.get(
      `/api/vehicles/document/download/${vehicleId}/${documentId}`,
      {
        responseType: "blob",
      }
    );

    return {
      blob: response.data,
      headers: response.headers,
    };
  } catch (error) {
    console.error(`Error fetching document:`, error);
    throw error;
  }
};

export const getVehicleAddOns = async (vehicleId: number): Promise<any> => {
  try {
    const response = await APICall.get<any>(
      `/api/vehicles/addons/vehicle/${vehicleId}`
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Vehicles data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error fetching data`, error);
    throw error; // Rethrow the error for further handling
  }
};
export const updatedVehicle = async ({
  id,
  vehicleName,
  licencePlateNo,
  firstRegistrationYear,
  vehicleOwner,
  vehicleUserId,
  brand,
  homologationType,
  commercialName,
  vinNo,
  maximumAuthorizedMass,
  curbWeight,
  lastRegistrationYear,
  category,
  numberOfAxles,
  displacement,
  engineHorsepower,
  fuelType,
  numberOfSeats,
  standingCapacity,
  maxSpeed,
  stationaryNoise,
  co2Emission,
  ecoCategory,
  ownerOIB,
  bodyType,
  purpose,
  firstRegistrationInCountry,
  permissiblePayload,
  length,
  width,
  height,
  tyreSize,
  model,
  kmOnInputDay,
  status,
  kmOnDay,
  kmDate,
}: {
  id: number;
  vehicleName: string;
  licencePlateNo: string;
  firstRegistrationYear: number;
  vehicleOwner: string;
  vehicleUserId: number;
  brand: string;
  homologationType: string;
  commercialName: string;
  vinNo: string;
  technicalMaxAllowedMass: number;
  maximumAuthorizedMass: number;
  curbWeight: number;
  lastRegistrationYear: number;
  category: string;
  numberOfAxles: number;
  displacement: number;
  engineHorsepower: number;
  fuelType: string;
  numberOfSeats: number;
  standingCapacity: number;
  maxSpeed: number;
  stationaryNoise: number;
  co2Emission: number;
  ecoCategory: number;
  ownerOIB: string;
  bodyType: string;
  purpose: string;
  firstRegistrationInCountry: string;
  permissiblePayload: number;
  length: number;
  width: number;
  height: number;
  tyreSize: number;
  model: string;
  kmOnInputDay: number;
  status: string;
  kmOnDay: number;
  kmDate: string;
}): Promise<any> => {
  try {
    const payload = {
      id,
      vehicleName,
      licencePlateNo,
      firstRegistrationYear,
      vehicleOwner,
      vehicleUserId,
      brand,
      homologationType,
      commercialName,
      vinNo,
      maximumAuthorizedMass,
      curbWeight,
      lastRegistrationYear,
      category,
      numberOfAxles,
      displacement,
      engineHorsepower,
      fuelType,
      numberOfSeats,
      standingCapacity,
      maxSpeed,
      stationaryNoise,
      co2Emission,
      ecoCategory,
      ownerOIB,
      bodyType,
      purpose,
      firstRegistrationInCountry,
      permissiblePayload,
      length,
      width,
      height,
      tyreSize,
      model,
      kmOnInputDay,
      status,
      kmOnDay,
      kmDate,
    };

    const response = await APICall.patch<any>(`/api/vehicles/${id}`, payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Vehicles data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating comment`, error);
    throw error;
  }
};
