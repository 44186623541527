import { Grid, Typography } from "@mui/material";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import { NavLink } from "react-router-dom";

export default function DashboardOverview() {
  return (
    <>
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "40px",
          width: "100%",
          overflow: "none",
        }}
      >
        <Grid style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Grid
            style={{
              backgroundColor: themeOptions.palette.primary.main,
              width: "25%",
              height: "300px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <NavLink
              to="/overview"
              style={{
                textDecoration: "none",
                textAlign: "center",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <img
                src="/overview.jpg"
                loading="lazy"
                alt="Overview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(23, 53, 93, 0.5)",
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10px",
                  position: "relative",
                  top: "110px",
                  zIndex: 2,
                }}
              >
                {translationsEn.dashboard.employeeOverview}
              </Typography>
            </NavLink>
          </Grid>
          <Grid
            style={{
              backgroundColor: themeOptions.palette.primary.main,
              width: "25%",
              height: "300px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <NavLink
              to="/employees"
              style={{
                textDecoration: "none",
                textAlign: "center",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <img
                src="/employees.jpg"
                alt="Employees"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(23, 53, 93, 0.5)",
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10px",
                  position: "relative",
                  top: "110px",
                  zIndex: 2,
                }}
              >
                {translationsEn.dashboard.employees}
              </Typography>
            </NavLink>
          </Grid>
          <Grid
            style={{
              backgroundColor: themeOptions.palette.primary.main,
              width: "25%",
              height: "300px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <NavLink
              to="/myRequest"
              style={{
                textDecoration: "none",
                textAlign: "center",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <img
                src="/myRequest.png"
                alt="My Request"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(23, 53, 93, 0.5)",
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10px",
                  position: "relative",
                  top: "110px",
                  zIndex: 2,
                }}
              >
                {translationsEn.dashboard.myRequest}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "40px",
          }}
        >
          <Grid
            style={{
              backgroundColor: themeOptions.palette.primary.main,
              width: "25%",
              height: "300px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <NavLink
              to="/teams"
              style={{
                textDecoration: "none",
                textAlign: "center",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <img
                src="/teams.jpg"
                alt="Teams"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(23, 53, 93, 0.5)",
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10px",
                  position: "relative",
                  top: "110px",
                  zIndex: 2,
                }}
              >
                {translationsEn.dashboard.team}
              </Typography>
            </NavLink>
          </Grid>
          <Grid
            style={{
              backgroundColor: themeOptions.palette.primary.main,
              width: "25%",
              height: "300px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <NavLink
              to="/cv"
              style={{
                textDecoration: "none",
                textAlign: "center",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <img
                src="/cv.jpg"
                loading="lazy"
                alt="Cv"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(23, 53, 93, 0.5)",
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10px",
                  position: "relative",
                  top: "110px",
                  zIndex: 2,
                }}
              >
                {translationsEn.dashboard.cv}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
