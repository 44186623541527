import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getAllEmployees } from "../../api/employeeApi";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import {
  createTravelRequestStep4,
  getTravelRequestStep4,
  uploadFiles,
} from "../../api/travelRequestApi";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../utils/alertContext";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../provider/authProvider";
import { handleAttachFiles } from "../../utils/filesHandler";

const PageContainer = styled("div")({
  maxWidth: "900px",
  margin: "auto",
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const validationSchema = Yup.object({
  description: Yup.string().required("Description is required"),
  reporterId: Yup.number().required("Reporter is required"),
  reportsToId: Yup.number().required("Reports to is required"),
});

const initialValues = {
  description: "",
  reporterId: 0,
  reportsToId: 0,
};

export default function TravelReport(props?: any) {
  const { travelRequestID, travelRequest } = props;
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [report, setReport] = useState<any>();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [attachedDocuments, setAttachedDocuments] = useState<File[]>([]);

  const isApproved = travelRequest?.status === "created"; // Check if status is approved

  const decoded: any = jwtDecode(token);

  useEffect(() => {
    if (travelRequestID)
      getTravelRequestStep4(travelRequestID)
        .then((response) => {
          setReport(response.data.data);
        })
        .catch((error) => showAlert(error, "error"));
  }, [travelRequestID]);

  useEffect(() => {
    if (report) {
      formik.setFieldValue("description", report.description);
      formik.setFieldValue("reporterId", report.reporter?.id);
      formik.setFieldValue("reportsToId", report.reportsTo?.id);
    }
  }, [report]);
  console.log(attachedDocuments);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      createTravelRequestStep4(values, report.id)
        .then((response) => {
          console.log("Travel request created:", response);

          if (attachedDocuments)
            attachedDocuments.forEach((item) => {
              uploadFiles(travelRequestID, item)
                .then((response) => {
                  showAlert("File uploaded successfully!", "success");
                })
                .catch((error) => {
                  showAlert("File upload error!", "error");
                })
                .finally(() => {
                  setAttachedDocuments([]);
                });
            });

          showAlert("Equipment added successfully!", "success");

          decoded.role.name === "ROLE_ADMIN"
            ? navigate("/all-travel-requests")
            : navigate("/my-travel-requests");
          showAlert("Travel report added successfully!", "success");
        })
        .catch((error) => {
          console.error("Travel request creation error:", error);
          showAlert(error.response?.data?.message || error.message, "error"); // Extract message for alert
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    const filter: any = { companyIds: [] };

    getAllEmployees(filter)
      .then((response) =>
        setUsers(response.data.map((item: any) => item.employeeDto))
      )
      .catch((error) => console.error("Failed to fetch employees:", error));
  }, []);

  return (
    <PageContainer>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item container xs={6} direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              startIcon={<AttachFileIcon />}
              sx={{
                backgroundColor: themeOptions.palette.primary.main,
                color: "white",
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                },
                borderRadius: "15px",
                maxHeight: "30px",
              }}
              component="label"
            >
              Attach document
              <VisuallyHiddenInput
                onChange={(event) =>
                  handleAttachFiles(
                    event,
                    attachedDocuments,
                    setAttachedDocuments
                  )
                }
                type="file"
              />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              {translationsEn.workOrders.document}
            </Typography>
            {attachedDocuments.map((file, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item>
                  <Typography>{file.name}</Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={translationsEn.createTravelRequests.description}
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              helperText={
                formik.touched.description &&
                formik.errors.description &&
                (formik.errors.description as string)
              }
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={users}
              getOptionLabel={(option: any) =>
                `${option.firstName} ${option.lastName}`
              }
              value={
                formik.values.reportsToId
                  ? users.find(
                      (user: any) => user.id === formik.values.reportsToId
                    )
                  : null
              }
              onChange={(event, value: any) => {
                formik.setFieldValue("reportsToId", value?.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(
                    formik.touched.reportsToId && formik.errors.reportsToId
                  )}
                  helperText={
                    formik.touched.reportsToId &&
                    formik.errors.reportsToId &&
                    (formik.errors.reportsToId as string)
                  }
                  label={translationsEn.createTravelRequests.reportsTo}
                  placeholder={translationsEn.createTravelRequests.chooseUser}
                />
              )}
              style={{ marginTop: "8px" }}
            />
            <Autocomplete
              options={users}
              getOptionLabel={(option: any) =>
                `${option.firstName} ${option.lastName}`
              }
              value={
                formik.values.reporterId
                  ? users.find(
                      (user: any) => user.id === formik.values.reporterId
                    )
                  : null
              }
              onChange={(event, value: any) => {
                formik.setFieldValue("reporterId", value?.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(
                    formik.touched.reporterId && formik.errors.reporterId
                  )}
                  helperText={
                    formik.touched.reporterId &&
                    formik.errors.reporterId &&
                    (formik.errors.reporterId as string)
                  }
                  label={translationsEn.createTravelRequests.reporter}
                  placeholder={translationsEn.createTravelRequests.chooseUser}
                />
              )}
              style={{ marginTop: "12px" }}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="large"
              style={{
                width: "100%",
                backgroundColor: "white",
                color: themeOptions.palette.primary.main,
                borderColor: themeOptions.palette.primary.main,
                textTransform: "none",
                marginTop: "20px",
              }}
              disabled={loading}
              onClick={() => {
                decoded.role.name === "ROLE_ADMIN"
                  ? navigate("/all-travel-requests")
                  : navigate("/my-travel-requests");
              }}
            >
              {translationsEn.createTravelRequests.cancel}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              style={{
                width: "100%",
                backgroundColor: themeOptions.palette.primary.main,
                textTransform: "none",
                marginTop: "20px",
              }}
              type="submit"
              disabled={loading || isApproved}
              endIcon={loading ? <CircularProgress size={20} /> : undefined}
            >
              {translationsEn.createTravelRequests.submit}
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageContainer>
  );
}
