import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import themeOptions from "../style/componentsStyle";
import translationsEn from "../i18n/translations_en";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllEmployees } from "../api/employeeApi";
import {
  editWorkOrders,
  getWorkOrderById,
  uploadFile,
} from "../api/workOrders";
import { useAlert } from "../utils/alertContext";
import dayjs from "dayjs";
import { getDocumentByWorkOrderId } from "../api/workOrders";
import {
  handleAttachFiles,
  handleDeleteFile,
  handleDownloadFile,
} from "../utils/filesHandler";
import { getAllCompanies } from "../api/overviewApi";
import { Status } from "../utils/constants/workOrderConstants";
import { useAuth } from "../provider/authProvider";
import { DatePicker } from "@mui/x-date-pickers";

const EditWorkOrder = () => {
  const [attachedDocuments, setAttachedDocuments] = useState<any[]>([]);
  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [allEmployees, setAllEmployees] = useState<any[]>([]);
  const { loggedUserRole } = useAuth();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const location = useLocation();
  const workOrder = location.state?.workOrder;

  useEffect(() => {
    if (!workOrder) {
      navigate("/workOrders");
    }
  }, [workOrder, navigate]);

  useEffect(() => {
    getAllCompanies()
      .then((response) => setAllCompanies(response))
      .catch((error) => console.error("Failed to fetch companies:", error));
  }, []);

  useEffect(() => {
    const filter = { companyIds: [], employmentStatusId: 0 };
    getAllEmployees(filter)
      .then((response) => setAllEmployees(response.data))
      .catch((error) => console.error("Failed to fetch employees:", error));
  }, []);

  useEffect(() => {
    const fetchWorkOrder = async () => {
      const workOrderId = location.pathname.split("/").pop();
      try {
        const fetchedWorkOrder = await getWorkOrderById(workOrderId);
        const fetchDocument = await getDocumentByWorkOrderId(workOrderId);

        formik.setValues({
          id: fetchedWorkOrder?.data.id || 0,
          title: fetchedWorkOrder?.data.title || "",
          reporter: fetchedWorkOrder?.data.reporter.id || "",
          status: fetchedWorkOrder?.data.status || Status.CREATED,
          companyType: fetchedWorkOrder?.data.companyType || "",
          companyName: fetchedWorkOrder?.data.company?.id || "",
          assignedUser: fetchedWorkOrder?.data.assigned.id || "",
          location: fetchedWorkOrder?.data.location || "",
          deadlineDate: dayjs(fetchedWorkOrder.data.deadlineDate),
          deadlineTime: fetchedWorkOrder?.data.deadlineTime || "",
          description: fetchedWorkOrder?.data.description || "",
          documents: fetchDocument?.data || [], // Set documents array from API
        });

        if (fetchDocument?.data) {
          setAttachedDocuments(fetchDocument.data); // Set fetched documents in state
        }
      } catch (error) {
        console.error("Failed to fetch work order:", error);
        navigate("/workOrders");
      }
    };

    fetchWorkOrder();
  }, [location, navigate]);

  const getStatusLabel = (status: string) => {
    return (
      translationsEn.status[status as keyof typeof translationsEn.status] ||
      status
    );
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      title: "",
      reporter: "",
      status: Status.CREATED,
      companyType: "",
      companyName: "",
      assignedUser: "",
      location: "",
      deadlineDate: dayjs(),
      deadlineTime: 0,
      description: "",
      documents: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(translationsEn.validation.required),
      reporter: Yup.string().required(translationsEn.validation.required),
      status: Yup.string().required(translationsEn.validation.required),
      companyType: Yup.string().required(translationsEn.validation.required),
      companyName: Yup.string().required(translationsEn.validation.required),
      assignedUser: Yup.string().required(translationsEn.validation.required),
      location: Yup.string().required(translationsEn.validation.required),
      deadlineDate: Yup.date().required(translationsEn.validation.required),
      deadlineTime: Yup.string().required(translationsEn.validation.required),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        const formatDate = (date: any) => {
          if (date) {
            return dayjs(date).isValid()
              ? dayjs(date).format("YYYY-MM-DD")
              : null;
          }
          return null;
        };
        const formatTime = (time: any) => {
          if (time) {
            return dayjs(time).isValid() ? dayjs(time).format("HH:mm") : "";
          }
          return "";
        };

        if (attachedDocuments)
          attachedDocuments.forEach((file) => {
            uploadFile(workOrder.id, file)
              .then((response) => {
                showAlert("File uploaded successfully!", "success");
              })
              .catch((error) => {
                showAlert("File upload error!", "error");
              })
              .finally(() => {
                setAttachedDocuments([]);
              });
          });
        console.log("Updated values:", values);
        const payload = {
          id: values.id,
          title: values.title,
          status: values.status,
          companyType: values.companyType,
          deadlineDate: formatDate(values.deadlineDate),
          deadlineTime: formatTime(values.deadlineTime),
          location: values.location,
          description: values.description,
          reporterId: values.reporter, // Uveri se da je ažurirana vrednost
          assignedId: values.assignedUser, // Uveri se da je ažurirana vrednost
          companyId: parseInt(values.companyName, 10), // Uveri se da je ažurirana vrednost
          document: "",
        };

        console.log(
          formik.values.assignedUser,
          formik.values.reporter,
          "User, reporter"
        );

        await editWorkOrders(payload);

        showAlert(`${translationsEn.workOrders.success}`, "success");
        navigate("/workOrders");
      } catch (error) {
        console.error("Error editing work order:", error);
        showAlert(`${translationsEn.workOrders.error}`, "error");
      }
    },
  });

  return (
    <>
      <Typography
        style={{
          margin: "40px",
          fontSize: "30px",
          fontWeight: "bold",
          color: "#555555",
        }}
      >
        {translationsEn.workOrders.editWorkOrders}
      </Typography>

      <Paper
        style={{
          padding: "20px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Grid></Grid>
          <Button
            variant="contained"
            startIcon={<AttachFileIcon />}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
              borderRadius: "15px",
            }}
            component="label"
          >
            Attach document
            <input
              type="file"
              hidden
              multiple
              onChange={(event) =>
                handleAttachFiles(
                  event,
                  attachedDocuments,
                  setAttachedDocuments
                )
              }
            />
          </Button>
        </Grid>

        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={3}
            style={{ maxWidth: "1500px", paddingLeft: "100px" }}
          >
            <Grid item xs={6}>
              <TextField
                label={translationsEn.workOrders.title}
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                style={{ borderRadius: "15px" }}
                error={formik.touched.title && Boolean(formik.errors.title)}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{translationsEn.workOrders.status}</InputLabel>
                <Select
                  label={translationsEn.workOrders.status}
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  name="status"
                  error={formik.touched.status && Boolean(formik.errors.status)}
                >
                  {Object.values(Status).map((status) => (
                    <MenuItem key={status} value={status}>
                      {getStatusLabel(status)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{translationsEn.workOrders.reporter}</InputLabel>
                <Select
                  label={translationsEn.workOrders.reporter}
                  value={formik.values.reporter}
                  onChange={formik.handleChange}
                  name="reporter"
                  error={
                    formik.touched.reporter && Boolean(formik.errors.reporter)
                  }
                  disabled
                >
                  {allEmployees.length > 0 ? (
                    allEmployees.map((employee) => (
                      <MenuItem
                        key={employee.employeeDto.id}
                        value={employee.employeeDto.id}
                      >
                        {employee.employeeDto.firstName +
                          " " +
                          employee.employeeDto.lastName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No employees available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth style={{ borderRadius: "15px" }}>
                <InputLabel id="companyType-label">
                  {translationsEn.workOrders.companyType}
                </InputLabel>
                <Select
                  label={translationsEn.workOrders.companyType}
                  labelId="companyType-label"
                  id="companyType"
                  name="companyType"
                  value={formik.values.companyType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.companyType &&
                    Boolean(formik.errors.companyType)
                  }
                  disabled
                >
                  <MenuItem value="Internal">Internal</MenuItem>
                  <MenuItem value="External">External</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth style={{ borderRadius: "15px" }}>
                <InputLabel id="companyName-label">
                  {translationsEn.workOrders.companyName}
                </InputLabel>
                <Select
                  label={translationsEn.workOrders.companyName}
                  labelId="companyName-label"
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  disabled
                >
                  {allCompanies.length > 0 ? (
                    allCompanies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No companies available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>
                  {translationsEn.workOrders.assignedUser}
                </InputLabel>
                <Select
                  label={translationsEn.workOrders.assignedUser}
                  value={
                    allEmployees.some(
                      (employee) =>
                        employee.employeeDto.id === formik.values.assignedUser
                    )
                      ? formik.values.assignedUser
                      : "" // Set to an empty string or default valid value if not found
                  }
                  onChange={formik.handleChange}
                  name="assignedUser"
                  error={
                    formik.touched.assignedUser &&
                    Boolean(formik.errors.assignedUser)
                  }
                  disabled={loggedUserRole?.id !== 2}
                >
                  {allEmployees.length > 0 ? (
                    allEmployees.map((employee) => (
                      <MenuItem
                        key={employee.employeeDto.id}
                        value={employee.employeeDto.id}
                      >
                        {employee.employeeDto.firstName +
                          " " +
                          employee.employeeDto.lastName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No employees available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label={translationsEn.workOrders.deadline}
                value={formik.values.deadlineDate}
                onChange={(date) => formik.setFieldValue("deadlineDate", date)}
                sx={{ width: "100%" }}
                format="DD-MM-YYYY"
                disabled
                slotProps={{
                  textField: {
                    helperText:
                      formik.touched.deadlineDate &&
                      formik.errors.deadlineDate &&
                      (formik.errors.deadlineDate as string),
                    error: Boolean(
                      formik.touched.deadlineDate && formik.errors.deadlineDate
                    ),
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={translationsEn.workOrders.location}
                id="location"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                disabled
                style={{ borderRadius: "15px" }}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={translationsEn.workOrders.description}
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                rows={4}
                fullWidth
                style={{ borderRadius: "15px" }}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                {translationsEn.workOrders.document}
              </Typography>
              {attachedDocuments.length > 0 ? (
                attachedDocuments.map((file, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <Typography>{file.filename}</Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography>No documents available</Typography>
              )}
            </Grid>
            <Grid item xs={12} style={{ display: "flex", width: "100%" }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ width: "100%" }}
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: themeOptions.palette.primary.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.primary.hover,
                    },
                  }}
                >
                  {translationsEn.buttons.confirm}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ width: "100%" }}
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: themeOptions.palette.primary.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.primary.hover,
                    },
                  }}
                  onClick={() => navigate("/workOrders")}
                >
                  {translationsEn.buttons.cancel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default EditWorkOrder;
