import {
  Modal,
  Box,
  styled,
  Grid,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import translationsEn from "../../i18n/translations_en";
import { useFormik } from "formik";
import * as Yup from "yup";
import themeOptions from "../../style/componentsStyle";
import { useEffect, useState } from "react";
import { useAlert } from "../../utils/alertContext";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getAllEmployees } from "../../api/employeeApi";
import {
  addEquipmentAttachment,
  createEquipment,
  editEquipment,
} from "../../api/equipmentApi";
import { getAllCompanies } from "../../api/overviewApi";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { handleAttachFiles } from "../../utils/filesHandler";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../provider/authProvider";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid #ccc",
};

const Title = styled("h3")({
  margin: "auto",
});

const HorizontalLine = styled("hr")({
  display: "block",
  height: "1px",
  border: "0",
  borderTop: "2px solid #ccc",
  margin: "1em 0",
  padding: "0",
  width: "100%",
});

const Content = styled("div")({
  width: "900px",
  margin: "auto",
  padding: "30px 0px",
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  status: Yup.string().required("Status is required"),
  // price: Yup.number().required("Price is required"),
  // warrantyStart: Yup.string().required("Warranty start  is required"),
  // warrantyEnd: Yup.string().required("Warranty end is required"),
  // usingFrom: Yup.string().required("Using from is required"),
  // quantity: Yup.number().required("Quantity is required"),
  // assignedToId: Yup.number().required("Assigned to is required"),
  // description: Yup.string().required("Description is required"),
  companyId: Yup.number().required("Company is required"),
});

const initialValues = {
  name: "",
  status: "",
  price: "",
  warrantyStart: "",
  warrantyEnd: "",
  usingFrom: "",
  quantity: "",
  assignedToId: "",
  description: "",
  companyId: "",
};

const statusList = [
  { name: "IN_USE", label: "In use" },
  { name: "FREE", label: "Free" },
  { name: "DELETED", label: "Deleted" },
];

export default function CreateEquipmentModal(props?: any) {
  const { open, closeModal, selectedEquipment } = props;
  const [users, setUsers] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const [attachedDocuments, setAttachedDocuments] = useState<File[]>([]);

  const { token } = useAuth();
  const decoded: any = jwtDecode(token);

  const userRole = decoded.role.name !== "ROLE_ADMIN";

  useEffect(() => {
    if (selectedEquipment) {
      formik.setFieldValue("name", selectedEquipment.name);
      formik.setFieldValue("status", selectedEquipment.status);
      formik.setFieldValue("price", selectedEquipment.price);
      formik.setFieldValue("warrantyStart", selectedEquipment.warrantyStart);
      formik.setFieldValue("warrantyEnd", selectedEquipment.warrantyEnd);
      formik.setFieldValue("usingFrom", selectedEquipment.usingFrom);
      formik.setFieldValue("quantity", selectedEquipment.quantity);
      formik.setFieldValue("assignedToId", selectedEquipment.assignedTo?.id);
      formik.setFieldValue("description", selectedEquipment.description);
      formik.setFieldValue("companyId", selectedEquipment.companyDto?.id);
    }
  }, [selectedEquipment]);

  useEffect(() => {
    const filter: any = { companyIds: [] };
    getAllEmployees(filter)
      .then((response) =>
        setUsers(response.data.map((item: any) => item.employeeDto))
      )
      .catch((error) => console.error("Failed to fetch employees:", error));

    getAllCompanies()
      .then((response) => setCompanies(response))
      .catch((error) => console.error("Failed to fetch companies:", error));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      console.log(values);
      setLoading(true);

      if (selectedEquipment) {
        editEquipment(selectedEquipment.id, values)
          .then(() => {
            closeModal();
            if (attachedDocuments)
              attachedDocuments.forEach((item) => {
                addEquipmentAttachment(selectedEquipment.id, item)
                  .then((response) => {
                    showAlert("File uploaded successfully!", "success");
                  })
                  .catch((response) => {
                    showAlert("File uploaded error!", "error");
                  })
                  .finally(() => {
                    setAttachedDocuments([]);
                  });
              });

            showAlert("Equipment edited successfully!", "success");
            action.resetForm();
          })
          .catch((error) => {
            showAlert(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        createEquipment(values)
          .then((response) => {
            if (attachedDocuments)
              attachedDocuments.forEach((item) => {
                addEquipmentAttachment(response.data.data.id, item)
                  .then((response) => {
                    showAlert("File uploaded successfully!", "success");
                  })
                  .catch((response) => {
                    showAlert("File uploaded error!", "error");
                  })
                  .finally(() => {
                    setAttachedDocuments([]);
                  });
              });

            closeModal();
            showAlert("Equipment added successfully!", "success");
            action.resetForm();
          })
          .catch((error) => {
            showAlert(error.error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  return (
    <Modal open={open} onClose={() => {}}>
      <Box sx={style}>
        <Title>
          {selectedEquipment
            ? translationsEn.equipment.edit
            : translationsEn.equipment.create}
        </Title>
        <HorizontalLine />
        <Content>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  disabled={userRole}
                  margin="dense"
                  label={translationsEn.equipment.name}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  helperText={
                    formik.touched.name &&
                    formik.errors.name &&
                    (formik.errors.name as string)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  margin="dense"
                  label={translationsEn.equipment.status}
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.status && formik.errors.status)}
                  helperText={
                    formik.touched.status &&
                    formik.errors.status &&
                    (formik.errors.status as string)
                  }
                  fullWidth
                >
                  {statusList.map((item) => {
                    return (
                      <MenuItem key={item.name} value={item.name}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={userRole}
                  type="number"
                  margin="dense"
                  label={translationsEn.equipment.price}
                  name="price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.price && formik.errors.price)}
                  helperText={
                    formik.touched.price &&
                    formik.errors.price &&
                    (formik.errors.price as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <span style={{ width: "100%" }}>
                  <DatePicker
                    disabled={userRole}
                    label={translationsEn.equipment.usingFrom}
                    format="DD/MM/YYYY"
                    value={
                      formik.values.usingFrom
                        ? dayjs(formik.values.usingFrom)
                        : null
                    }
                    onChange={(date) => formik.setFieldValue("usingFrom", date)}
                    slotProps={{
                      textField: {
                        helperText:
                          formik.touched.usingFrom &&
                          formik.errors.usingFrom &&
                          (formik.errors.usingFrom as string),
                        error: Boolean(
                          formik.touched.usingFrom && formik.errors.usingFrom
                        ),
                      },
                    }}
                    sx={{ margin: "10px 0", width: "100%" }}
                  />
                </span>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <span style={{ width: "100%" }}>
                  <DatePicker
                    disabled={userRole}
                    label={translationsEn.equipment.warrantyStart}
                    format="DD/MM/YYYY"
                    value={
                      formik.values.warrantyStart
                        ? dayjs(formik.values.warrantyStart)
                        : null
                    }
                    onChange={(date) =>
                      formik.setFieldValue("warrantyStart", date)
                    }
                    slotProps={{
                      textField: {
                        helperText:
                          formik.touched.warrantyStart &&
                          formik.errors.warrantyStart &&
                          (formik.errors.warrantyStart as string),
                        error: Boolean(
                          formik.touched.warrantyStart &&
                            formik.errors.warrantyStart
                        ),
                      },
                    }}
                    sx={{ margin: "10px 0", width: "100%" }}
                  />
                </span>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <span style={{ width: "100%" }}>
                  <DatePicker
                    disabled={userRole}
                    label={translationsEn.equipment.warrantyEnd}
                    format="DD/MM/YYYY"
                    value={
                      formik.values.warrantyEnd
                        ? dayjs(formik.values.warrantyEnd)
                        : null
                    }
                    onChange={(date) =>
                      formik.setFieldValue("warrantyEnd", date)
                    }
                    slotProps={{
                      textField: {
                        helperText:
                          formik.touched.warrantyEnd &&
                          formik.errors.warrantyEnd &&
                          (formik.errors.warrantyEnd as string),
                        error: Boolean(
                          formik.touched.warrantyEnd &&
                            formik.errors.warrantyEnd
                        ),
                      },
                    }}
                    sx={{ margin: "10px 0", width: "100%" }}
                  />
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={userRole}
                  type="number"
                  margin="dense"
                  label={translationsEn.equipment.quantity}
                  name="quantity"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.quantity && formik.errors.quantity
                  )}
                  helperText={
                    formik.touched.quantity &&
                    formik.errors.quantity &&
                    (formik.errors.quantity as string)
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  sx={{ marginTop: "8px" }}
                  options={users}
                  getOptionLabel={(option: any) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  value={
                    formik.values.assignedToId
                      ? users.find(
                          (user: any) => user.id === formik.values.assignedToId
                        )
                      : null
                  }
                  onChange={(event, value: any) => {
                    formik.setFieldValue("assignedToId", value.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        formik.touched.assignedToId &&
                          formik.errors.assignedToId
                      )}
                      helperText={
                        formik.touched.assignedToId &&
                        formik.errors.assignedToId &&
                        (formik.errors.assignedToId as string)
                      }
                      label={translationsEn.equipment.assignedTo}
                      placeholder={
                        translationsEn.createTravelRequests.chooseUser
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={userRole}
                  margin="dense"
                  label={translationsEn.equipment.description}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.description && formik.errors.description
                  )}
                  helperText={
                    formik.touched.description &&
                    formik.errors.description &&
                    (formik.errors.description as string)
                  }
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={userRole}
                  sx={{ marginTop: "8px" }}
                  options={companies}
                  getOptionLabel={(option: any) => option.name}
                  value={
                    formik.values.companyId
                      ? companies.find(
                          (company: any) =>
                            company.id === formik.values.companyId
                        )
                      : null
                  }
                  onChange={(event, value: any) => {
                    formik.setFieldValue("companyId", value.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        formik.touched.companyId && formik.errors.companyId
                      )}
                      helperText={
                        formik.touched.companyId &&
                        formik.errors.companyId &&
                        (formik.errors.companyId as string)
                      }
                      label={translationsEn.createTravelRequests.company}
                      placeholder={
                        translationsEn.createTravelRequests.chooseCompany
                      }
                    />
                  )}
                />
                <div style={{ display: "flex" }}>
                  <Button
                    // disabled={true}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<AttachFileIcon />}
                    style={{
                      textTransform: "none",
                      backgroundColor: themeOptions.palette.primary.main,
                      width: "50%",
                      height: "40px",
                      marginTop: "28px",
                    }}
                  >
                    {translationsEn.createTravelRequests.uploadFiles}
                    <VisuallyHiddenInput
                      onChange={(event) =>
                        handleAttachFiles(
                          event,
                          attachedDocuments,
                          setAttachedDocuments
                        )
                      }
                      type="file"
                    />
                  </Button>
                  <div style={{ margin: "auto", paddingTop: "20px" }}>
                    {attachedDocuments[0]?.name}
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    color: themeOptions.palette.primary.main,
                    borderColor: themeOptions.palette.primary.main,
                    textTransform: "none",
                    marginTop: "20px",
                  }}
                  disabled={loading}
                  onClick={() => {
                    closeModal();
                    formik.resetForm();
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    translationsEn.createTravelRequests.cancel
                  )}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: themeOptions.palette.primary.main,
                    textTransform: "none",
                    marginTop: "20px",
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    translationsEn.createTravelRequests.submit
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Content>
      </Box>
    </Modal>
  );
}
