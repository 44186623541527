import {
  Paper,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Autocomplete,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { deleteVehicle, getVehicles } from "../../api/vehiclesApi";
import { useNavigate } from "react-router-dom";
import AddVehicleService from "./addServiceModal";
import VehiclePreviewModal from "./vehiclesPreviewModal";
import DeleteModal from "../../modals/confirmationsModal/deleteModal";
import { getAllEmployees } from "../../api/employeeApi";

export default function VehiclesOverview() {
  const [dateFrom, setDateFrom] = useState<any>("");
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [vehicleSelected, setVehiclesSelected] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [addService, setAddService] = useState<boolean>(false);
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;
  const [employeeList, setEmployeeList] = useState<any[]>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [vehicleToDelete, setVehicleToDelete] = useState<any>();
  const [filterCriteria, setFilterCriteria] = useState({
    vehicleName: null,
    firstRegistrationYear: null,
    assignedUserId: null,
    category: null,
    numberOfSeats: null,
    ecoCategory: null,
  });

  const fetchEmployees = () => {
    const filter = { companyIds: [], employmentStatusId: 1 };

    getAllEmployees(filter)
      .then((response) => {
        setEmployeeList(response.data);
      })
      .catch((error) => console.error("Failed to fetch teams:", error));
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const useResponsiveHeight = () => {
    const [height, setHeight] = useState("20px");

    useEffect(() => {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 1536) {
          setHeight("369px");
        } else {
          setHeight("401px");
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return height;
  };

  const closeAddServiceModal = () => {
    setAddService(false);
    setRefresh(!refresh);
  };
  const tableHeight = useResponsiveHeight();
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await getVehicles(filterCriteria);
        setPage(0);
        setTableData(response.data.data);
      } catch (error) {
        console.error("Failed to fetch vehicles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [filterCriteria, refresh]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilterCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    setFilterCriteria((prev) => ({
      ...prev,
      [name]: value === "" ? null : value, // Set to null if value is empty
    }));
  };
  const handleDeleteClick = (vehicle: any) => {
    setVehicleToDelete(vehicle);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteVehicle(vehicleToDelete.id);
      setRefresh(!refresh);
      setDeleteModalOpen(false);
    } catch (error) {
      console.error("Failed to delete vehicle:", error);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleEditClick = (dataRow: any) => {
    console.log("Edit clicked for", dataRow.vehicleName);
    navigate("/newVehicle", {
      state: { vehicleId: dataRow.id, isEditing: true, vehicleData: dataRow },
    });
  };

  console.log(employeeList, "emp list");

  const handleRowClick = (event: any, dataRow: any) => {
    const target = event.target;
    const excludeClick = target.closest(".exclude-click");

    if (!excludeClick) {
      setVehiclesSelected(dataRow);

      navigate("/previewVehicle", {
        state: { previewItem: dataRow },
      });
    }
  };
  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.vehicles.pageName}
        </Typography>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                label={translationsEn.vehicles.vehicleName}
                id="vehicleName"
                name="vehicleName"
                value={filterCriteria.vehicleName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={translationsEn.vehicles.firstRegistrationYear}
                id="firstRegistrationYear"
                name="firstRegistrationYear"
                value={filterCriteria.firstRegistrationYear}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl style={{ width: "70%" }}>
                <InputLabel id="assignedUserId-label">
                  {translationsEn.vehicles.vehicleUser}
                </InputLabel>
                <Select
                  labelId="assignedUserId-label"
                  label={translationsEn.vehicles.vehicleUser}
                  id="assignedUserId"
                  name="assignedUserId"
                  value={filterCriteria.assignedUserId ?? ""} // Use empty string for null
                  onChange={handleSelectChange}
                >
                  {/* Default option with a null value */}
                  <MenuItem value="">
                    {translationsEn.vehicles.assignedUser}{" "}
                    {/* Display default label */}
                  </MenuItem>
                  {employeeList?.map((employee) => (
                    <MenuItem
                      key={employee.employeeDto?.id}
                      value={employee.employeeDto?.id}
                    >
                      {`${employee.employeeDto?.firstName} ${employee.employeeDto?.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={translationsEn.vehicles.category}
                id="category"
                name="category"
                value={filterCriteria.category}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={translationsEn.vehicles.numberOfSeats}
                id="numberOfSeats"
                name="numberOfSeats"
                value={filterCriteria.numberOfSeats}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={translationsEn.vehicles.ecoCategory}
                id="ecoCategory"
                name="ecoCategory"
                value={filterCriteria.ecoCategory}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "auto" }}>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Box />
          <Button
            variant="contained"
            style={{
              margin: "10px",
              borderRadius: "20px",
              height: "50%",
            }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
              textTransform: "none",
            }}
            onClick={() =>
              navigate("/newVehicle", {
                state: { vehicleId: vehicleSelected }, // Pass ID as state
              })
            }
          >
            {translationsEn.vehicles.createNewButton}
          </Button>
        </Grid>
        <TableContainer sx={{ maxHeight: tableHeight }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.vehicleName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.assignedUser}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.vehicleCategory}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.licencePlateNo}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.enviroment}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.firstRegistrationYear}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.vehicleStatus}
                </TableCell>

                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.addService}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.action}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.deleteVehicle}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={9} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((dataRow: any, index: any) => (
                  <TableRow
                    onClick={(event: any) => {
                      if (
                        event.currentTarget
                          .querySelector(".exclude-click")
                          .contains(event.target)
                      ) {
                        return;
                      }
                      handleRowClick(event, dataRow);
                    }}
                    key={dataRow.id}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "20px",
                    }}
                  >
                    <TableCell align="left">{dataRow.vehicleName}</TableCell>
                    <TableCell align="left">
                      {dataRow.vehicleUser
                        ? `${dataRow.vehicleUser?.firstName} ${dataRow.vehicleUser?.lastName}`
                        : "No assigned user"}
                    </TableCell>
                    <TableCell align="left">{dataRow.category}</TableCell>
                    <TableCell align="left">{dataRow.licencePlateNo}</TableCell>
                    <TableCell align="left">{dataRow.ecoCategory}</TableCell>
                    <TableCell align="left">
                      {dataRow.firstRegistrationYear}
                    </TableCell>
                    <TableCell align="left">{dataRow.status}</TableCell>
                    <TableCell align="left" className="exclude-click">
                      <Button
                        style={{ padding: "5px" }}
                        sx={{
                          backgroundColor: themeOptions.palette.primary.main,
                          color: "white",
                          ":hover": {
                            background: themeOptions.palette.primary.hover,
                          },
                          textTransform: "none",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setAddService(true);
                          setVehiclesSelected(dataRow.id);
                        }}
                      >
                        {translationsEn.vehicles.addService}
                      </Button>
                    </TableCell>

                    <TableCell align="left" className="exclude-click">
                      <Button
                        style={{ padding: "5px" }}
                        sx={{
                          background: themeOptions.palette.primary.main,
                          color: "white",
                          ":hover": {
                            background: themeOptions.palette.primary.hover,
                          },
                        }}
                        onClick={() => {
                          handleEditClick(dataRow);
                          console.log("Edit clicked for", dataRow.vehicleName);
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>

                    <TableCell align="left" className="exclude-click">
                      <Button
                        style={{ padding: "5px" }}
                        sx={{
                          backgroundColor: themeOptions.palette.cancel.main,
                          color: "white",
                          ":hover": {
                            backgroundColor: themeOptions.palette.cancel.hover,
                          },
                          textTransform: "none",
                        }}
                        onClick={() => handleDeleteClick(dataRow)}
                      >
                        {translationsEn.vehicles.deleteVehicle}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <AddVehicleService
        open={addService}
        closeModal={closeAddServiceModal}
        vehicleId={vehicleSelected}
      />
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
}
